import React, { useState, useEffect } from "react";
import {
  View,
  TouchableOpacity,
  Text,
  Platform,
  Dimensions,
} from "react-native";
import PdfViewer from "../../../new/components/PdfViewer";
import { useNavigation } from "@react-navigation/native";
import { Video, Audio } from "expo-av";
import * as WebBrowser from "expo-web-browser";
import * as FileSystem from "expo-file-system";
import * as Linking from "expo-linking";
import * as Sharing from "expo-sharing";
import {
  Spinner,
  SpinnerModal,
  HeaderButton,
  ErrorMessage,
} from "../../../components";
import {
  getErrorMessage,
  IOWNAError,
  sanitizeFilename,
  handleError,
} from "../../../utils";
import { getSingleAdvice, getUserSubmission } from "../../../graphql";
import {
  useAtom,
  pdfScreenDataAtom,
  currentOrganisationAtom,
  bundleAtom,
  setBundle,
  currentRoleTypeAtom,
  setSubmissionId,
  isWebAtom,
} from "../../../atoms";
import { useQuery } from "react-query";

import { optionsBack } from "../../../headerUtils";
import { screen, containerWithoutLoader } from "../../../reactUtils";

const Button = ({ text, onPress, style = {} }) => {
  const web = useAtom(isWebAtom);
  return (
    <TouchableOpacity
      testID={`${text}`}
      accessibilityLabel={Platform.select({ android: `${text}` })}
      style={{
        backgroundColor: "white",
        borderColor: "rgb(0, 78, 122)",
        borderWidth: 1,
        padding: web ? 8 : 5,
        borderRadius: web ? 3 : 0,
        marginLeft: 20,

        ...style,
      }}
      onPress={onPress}
    >
      <Text style={{ color: "rgb(0, 78, 122)" }}>{text}</Text>
    </TouchableOpacity>
  );
};

export const PdfScreen = screen(({ route }) => {
  const navigation = useNavigation();
  const { id, contentId, canSetAdvice, canSendAdvice, consumer, item } =
    route.params;
  const { filename, displayName, url, form } = useAtom(pdfScreenDataAtom);

  const bundle = useAtom(bundleAtom);
  const [iframeLoading, setIframeLoading] = useState(true);
  const [error, setError] = useState(null);
  const currentRoleType = useAtom(currentRoleTypeAtom);

  const submissionId = contentId;
  const maybeOrganisationId =
    currentRoleType === "personal"
      ? null
      : currentOrganisationAtom.getValue().id;

  const { data: submission, isLoading: isSubmissionLoading } = useQuery(
    [maybeOrganisationId, "submissions", submissionId],
    () =>
      getUserSubmission({ id: submissionId }).then(
        (r) => r.data.getUserSubmission
      )
  );

  const hasCompletedSubmission = submission && !!submission.updatedAt;
  const isVideo = filename.endsWith(".mp4");
  useEffect(() => {
    setSubmissionId(contentId);
  }, [contentId]);

  const SendButton = () => {
    return (
      <Button
        text="Share"
        style={{
          borderRadius: 35,
          borderWidth: 2,
          width: 68,
          alignItems: "center",
          justifyContent: "center",
        }}
        onPress={() => {
          navigation.navigate("SendPlanNavigator", {
            screen: "SendScreen",
            params: {
              contents: [item],
            },
          });
        }}
      />
    );
  };

  const ShareButton = () => {
    return (
      <Button
        text="Share"
        style={{
          borderRadius: 35,
          borderWidth: 2,
          alignItems: "center",
          justifyContent: "center",
        }}
        onPress={() => {
          navigation.navigate(route.params.selectionOriginatingScreen, {
            contents: [
              item,
              ...route.params.selected.filter((i) => i.id !== item.id),
            ],
          });
        }}
      />
    );
  };

  const AddToBundle = () => {
    const found = bundle.items.find((item) => item.id === id);
    return !found ? (
      <Button
        text={"Add To Bundle"}
        style={{
          borderRadius: 35,
          borderWidth: 2,
          width: 125,
          alignItems: "center",
          justifyContent: "center",
        }}
        onPress={() => {
          setBundle({
            id: null,
            name: null,
            items: bundle.items.concat([
              {
                id: id,
                displayName: displayName,
                form: form,
              },
            ]),
          });
          navigation.goBack();
        }}
      />
    ) : null;
  };

  const PreviewForm = () => {
    if (form) {
      if (currentRoleType === "personal" || consumer) {
        return submission ? (
          <Button
            text={
              hasCompletedSubmission ? "View Completed Form" : "Complete Form"
            }
            onPress={() =>
              navigation.navigate("FormScreen", {
                ...form,
                submission: submission,
                consumer: route.params.consumer,
              })
            }
          />
        ) : null;
      } else {
        return (
          <Button
            text={"Preview Form"}
            style={{
              borderRadius: 35,
              borderWidth: 2,
              alignItems: "center",
              justifyContent: "center",
            }}
            onPress={() =>
              navigation.navigate("FormScreen", {
                ...form,
                submission: submission,
              })
            }
          />
        );
      }
    } else {
      return null;
    }
    return null;
  };

  if (error) {
    return (
      <View
        style={{
          flex: 1,
          backgroundColor: "white",
          alignItems: "center",
          justifyContent: "center",
        }}
      >
        <ErrorMessage err={getErrorMessage(error)} />
      </View>
    );
  }

  if (Platform.OS === "web") {
    return (
      <View style={{ flex: 1, backgroundColor: "white" }}>
        {canSetAdvice || canSendAdvice || form ? (
          <View
            style={{
              flexDirection: "row",
              alignItems: "flex-start",
              paddingVertical: 10,
              paddingLeft: 20,
            }}
          >
            {canSetAdvice ? (
              <ShareButton />
            ) : canSendAdvice ? (
              <SendButton />
            ) : null}

            {canSetAdvice ? <AddToBundle /> : null}

            <PreviewForm />
          </View>
        ) : null}
        <View
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        >
          {iframeLoading ? (
            <View style={{ position: "absolute", margin: "auto auto" }}>
              <Spinner />
            </View>
          ) : null}

          <iframe
            key={id}
            src={url}
            style={{ display: "flex", flexDirection: "column", flex: 1 }}
            width="100%"
            height="100%"
            onLoad={() => setIframeLoading(false)}
            onError={() => setError(new IOWNAError("Could not load the Pdf"))}
          />
        </View>
      </View>
    );
  }
  return (
    <>
      {canSetAdvice || canSendAdvice || form ? (
        <View
          style={{
            flexDirection: "row",
            alignItems: "flex-start",
            paddingVertical: 10,
            backgroundColor: "white",
          }}
        >
          {canSetAdvice ? (
            <ShareButton />
          ) : canSendAdvice ? (
            <SendButton />
          ) : null}
          {canSetAdvice ? <AddToBundle /> : null}

          <PreviewForm />
        </View>
      ) : null}
      {isVideo ? (
        <View
          style={{ flex: 1, alignItems: "center", justifyContent: "center" }}
        >
          {iframeLoading ? (
            <View>
              <Spinner />
            </View>
          ) : null}
          <Video
            rate={1.0}
            volume={1.0}
            isMuted={false}
            isLooping={false}
            resizeMode={Video.RESIZE_MODE_CONTAIN}
            useNativeControls={true}
            shouldPlay={true}
            source={{
              uri: url,
            }}
            onLoad={async () => {
              setIframeLoading(false);
              await Audio.setIsEnabledAsync(true);
              await Audio.setAudioModeAsync({
                playsInSilentModeIOS: true,
              });
            }}
            onError={() => setError(new IOWNAError("Could not load the Pdf"))}
            style={{
              width: Dimensions.get("window").width,
              height: Dimensions.get("window").height - 200,
            }}
          />
        </View>
      ) : (
        // <VideoPlayer
        //   videoProps={{
        //     shouldPlay: false,
        //     rate: 1.0,
        //     volume: 1.0,
        //     isMuted: false,
        //     shouldPlay: false,
        //     isLooping: false,
        //     resizeMode: Video.RESIZE_MODE_CONTAIN,
        //     useNativeControls: true,
        //     source: {
        //       uri: url,
        //     },
        //   }}
        //   inFullscreen={false}
        // />
        <PdfViewer uri={url} />
      )}
    </>
  );
});

const PdfActions = containerWithoutLoader(() => {
  const [showModal, setShowModal] = useState(false);
  const { id, displayName } = useAtom(pdfScreenDataAtom);
  const filename = sanitizeFilename(displayName) + ".pdf";
  return (
    <>
      <HeaderButton
        icon="download"
        title="Download"
        onPress={handleError(
          async () => {
            const res = await getSingleAdvice({ id: id });
            const url = res.data.getAdvice.downloadUrl;
            if (Platform.OS === "web") {
              Linking.openURL(url);
            } else if (Platform.OS === "android") {
              WebBrowser.openBrowserAsync(url);
            } else {
              setShowModal(true);
              const downloadResumable = FileSystem.createDownloadResumable(
                url,
                FileSystem.documentDirectory + filename,
                {}
              );
              const { uri } = await downloadResumable.downloadAsync();
              await Sharing.shareAsync(uri);
            }
          },
          () => setShowModal(false)
        )}
      />
      <SpinnerModal isVisible={showModal} onClose={() => setShowModal(false)} />
    </>
  );
});

const PdfTitle = containerWithoutLoader(() => {
  const { displayName } = useAtom(pdfScreenDataAtom);
  return (
    <View style={{ marginLeft: 13, marginRight: 10 }}>
      <Text
        style={{ fontSize: 20, fontWeight: "500", color: "rgb(0, 78, 122)" }}
      >
        {displayName}
      </Text>
    </View>
  );
});

export const PdfOptions = (web) =>
  optionsBack(
    web,
    () => <PdfTitle />,
    () => <PdfActions />
  );

export default PdfScreen;

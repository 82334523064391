import React, { useState, useRef, useEffect } from "react";
import { View, StyleSheet, Animated, Easing, Platform } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import Touch from "./Touch";

const Arrow = ({ arrowAngle }) => {
  return (
    <Animated.View
      style={{
        transform: [{ rotateZ: arrowAngle }],
      }}
    >
      <Ionicons name="arrow-forward" size={24} color="#77706e" />
    </Animated.View>
  );
};

const Accordion = ({
  open,
  title,
  children,
  onPress = () => {},
  showBody = true,
  showArrow = true,
  expandIcon,
  collapseIcon,
  titleStyles = {},
  bodyStyles = {},
}) => {
  const animatedController = useRef(new Animated.Value(0)).current;
  const [bodySectionHeight, setBodySectionHeight] = useState(50);

  const bodyHeight = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: [0, bodySectionHeight],
  });

  const arrowAngle = animatedController.interpolate({
    inputRange: [0, 1],
    outputRange: ["0rad", `${Math.PI / 2}rad`],
  });

  useEffect(() => {
    if (showBody) {
      if (open) {
        Animated.timing(animatedController, {
          duration: 300,
          toValue: 1,
          easing: Easing.bezier(0.4, 0.0, 0.2, 1),
          useNativeDriver: false,
        }).start();
      } else {
        Animated.timing(animatedController, {
          duration: 300,
          toValue: 0,
          easing: Easing.bezier(0.4, 0.0, 0.2, 1),
          useNativeDriver: false,
        }).start();
      }
    }
  }, [open, showBody]);

  return (
    <>
      <Touch withoutFeedback={showArrow} onPress={onPress} style={{ flex: 1}}>
        <View
          style={[
            styles.titleContainer,
            {
              paddingRight: showArrow ? 20 : 0,
              ...titleStyles,
          
            },
          ]}
        >
          {title}
          <View >
            {showArrow &&
              (open
                ? collapseIcon || <Arrow arrowAngle={arrowAngle} />
                : expandIcon || <Arrow arrowAngle={arrowAngle} />)}
          </View>
        </View>
      </Touch>
      {showBody && (
        <Animated.View
          style={{
            backgroundColor: "#efefef",
            overflow: "hidden",
            height: bodyHeight,
            ...bodyStyles,
          }}
        >
          <View                                                                                                                                                                                                               
            style={styles.bodyContainer}
            onLayout={(event) => {
              setBodySectionHeight(event.nativeEvent.layout.height);
            }}
          >
            {children}
          </View>
        </Animated.View>
      )}
    </>
  );
};
export default Accordion;

const styles = StyleSheet.create({
  titleContainer: {
    display: "flex",
    flexDirection: "row",
    justifyContent: "space-between",
    alignItems: "center",
    borderTopWidth: 1,
    borderBottomWidth: 1,
    borderColor: "#EFEFEF",
    backgroundColor: "#ffffff",
    ...Platform.select({
      web: {
        cursor: "pointer",
        "user-select": "none",
      },
    }),
  },
  bodyContainer: {
    position: "absolute",
    bottom: 0,
    width: "100%",
  },
});

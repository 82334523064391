import React, { useEffect, useState, useRef } from "react";
import { createDrawerNavigator } from "@react-navigation/drawer";
import { DrawerActions } from "@react-navigation/native";
import { Platform } from "react-native";
import AsyncStorage from "@react-native-async-storage/async-storage";
import { Auth } from "aws-amplify";
import Constants from "expo-constants";
import * as Notifications from "expo-notifications";
import DrawerContent from "../../containers/DrawerContent";
import PractitionerNavigator from "./PractitionerNavigator";
import OrganisationsNavigator from "./OrganisationsScreen";
import UsersNavigator from "./UsersNavigator";
import NotificationsNavigator from "./NotificationsNavigator";
import TodoNavigator from "./TodoNavigator";
import LibraryNavigator from "./LibraryNavigator";
import ProfileNavigator from "./ProfileNavigator";
import ContentNavigator from "./ContentNavigator";
import BundleNavigator from "./BundleNavigator";
import TagsNavigator from "./TagsNavigator";
import ConsumerNavigator from "./ConsumerNavigator";
import ContactsNavigator from "./ContactsNavigator";
import ActivityNavigator from "./ActivityNavigator";
import SendPlanNavigator from "./SendPlanNavigator";
import TrialOrganisationSetupScreen from "./TrialOrganisationSetupScreen";
import {
  confirm,
  setPdfId,
  setCurrentNavigator,
  markNotification,
  addPushNotification,
  setCurrentRoleType,
  userPushTokensAtom,
  useAtom,
  isWebAtom
} from "../../atoms";
import {
  registerForPushNotifications,
  refreshCurrentSession,
  showRefreshError,
  delay
} from "../../utils";
import { useHasAnyRoles } from "../../hooks";
import { screen } from "../../reactUtils";
import { ThemeProvider } from "@emotion/react";
import { MainTheme } from "../../themes";
import { QueryClient, QueryClientProvider } from "react-query";

const queryClient = new QueryClient();

if (Platform.OS === "android") {
  Notifications.setNotificationChannelAsync("default", {
    name: "default",
    importance: Notifications.AndroidImportance.MAX,
    vibrationPattern: [0, 250, 250, 250],
    lightColor: "#FF231F7C"
  });
}
// When a notification is received while the app is running,
// using this function you can set a callback that will decide whether the notification should be shown to the user or not.
Notifications.setNotificationHandler({
  handleNotification: async () => ({
    shouldShowAlert: false,
    shouldSetBadge: false,
    shouldPlaySound: true
  })
});

const Drawer = createDrawerNavigator();

export default screen(({ navigation }) => {
  const web = useAtom(isWebAtom);
  const [drawerOpenByDefault, setDrawerOpenByDefault] = useState(web);
  // const [drawerOpenByDefault, setDrawerOpenByDefault] = useState(true);
  // useEffect(() => {
  //   if (setDrawerOpenByDefault) {
  //     const unsubscribe = navigation.addListener('drawerClose', (e) => {
  //       setDrawerOpenByDefault(web);
  //     })
  //     return unsubscribe;
  //   }
  // }, [drawerOpenByDefault]);
  const pushTokens = useAtom(userPushTokensAtom);
  const hasSystemAdmin = useHasAnyRoles(["system_admin"]);
  const hasSupport = useHasAnyRoles(["support"]);
  const hasOrgRoles = useHasAnyRoles([
    "organisation_admin",
    "organisation_owner"
  ]);
  const hasProfessionalRoles = useHasAnyRoles([
    "practitioner",
    "practitioner_external",
    "practitioner_proxy",
    "curator"
  ]);
  const notificationListener = useRef();
  const responseListener = useRef();
  const refreshTokenRef = useRef();
  let screenName = "ConsumerNavigator";
  let currentRoleType = "personal";
  let initialParams = {};
  if (hasSystemAdmin || hasSupport) {
    screenName = "OrganisationsNavigator";
    currentRoleType = "professional";
  } else if (hasProfessionalRoles) {
    // screenName = web ? "SendPlanNavigator" : "PractitionerNavigator";
    // screenName = "SendPlanNavigator";
    screenName = "PractitionerNavigator";
    currentRoleType = "professional";
  } else if (hasOrgRoles) {
    screenName = "UsersNavigator";
    currentRoleType = "professional";
  }
  useEffect(() => {
    setCurrentNavigator(screenName);
    setCurrentRoleType(currentRoleType);
  }, [screenName]);
  useEffect(() => {
    if (Platform.OS !== "web" && Constants.isDevice) {
      AsyncStorage.getItem("askedNotificationsAccess").then((value) => {
        if (!JSON.parse(value || "null")) {
          registerForPushNotifications(pushTokens);
          AsyncStorage.setItem("askedNotificationsAccess", "true");
        }
      });

      // This listener is fired whenever a notification is received while the app is foregrounded
      notificationListener.current =
        Notifications.addNotificationReceivedListener(async (notification) => {
          const { title, body, data } = notification.request.content;
          const userNotification = JSON.parse(data.userNotification);
          addPushNotification(userNotification, false);
          if (userNotification.type === "new_content") {
            confirm(title, body + "\n\n View the content  now?", async () => {
              markNotification(userNotification);
              setPdfId(userNotification.data["adviceId"]);
              await delay(2000);
              navigation.navigate("PdfScreen", {
                id: userNotification.data["adviceId"],
                contentId: userNotification.id,
                consumer: true
              });
              return true;
            })();
          } else if (userNotification.type === "submission_complete") {
            confirm(title, body + "\n\n View results now?", () => {
              navigation.navigate("NotificationsNavigator");
              return true;
            })();
          }
        });
      // // This listener is fired whenever a user taps on or interacts with a notification (works when app is foregrounded, backgrounded, or killed)
      // TODO: need to test killed
      responseListener.current =
        Notifications.addNotificationResponseReceivedListener(
          async (response) => {
            const { data } = response.notification.request.content;
            const userNotification = JSON.parse(data.userNotification);
            addPushNotification(userNotification, true);
            if (userNotification.type === "new_content") {
              markNotification(userNotification);
              setPdfId(userNotification.data["adviceId"]);
              navigation.navigate("PdfScreen", {
                id: userNotification.data["adviceId"],
                contentId: userNotification.id,
                consumer: true
              });
            } else if (userNotification.type === "submission_complete") {
              navigation.navigate("NotificationsNavigator");
            }
          }
        );
    }

    if (!refreshTokenRef.current) {
      refreshTokenRef.current = setInterval(async () => {
        try {
          const session = await Auth.currentSession();
          const expiresAt = session.getIdToken().getExpiration() * 1000;
          const currentTime = new Date().getTime() + 5 * 60 * 1000;
          // const currentTime = new Date(expiresAt + 30 * 1000).getTime();
          // console.log('expiresAt', expiresAt, currentTime);
          if (expiresAt <= currentTime) {
            await refreshCurrentSession();
          }
        } catch (err) {
          console.log("refresh failed", err);
          clearInterval(refreshTokenRef.current);
          refreshTokenRef.current = null;
          showRefreshError(navigation);
        }
      }, 1 * 60 * 1000);
    }

    return () => {
      if (Platform.OS !== "web" && Constants.isDevice) {
        Notifications.removeNotificationSubscription(notificationListener);
        Notifications.removeNotificationSubscription(responseListener);
      }
      clearInterval(refreshTokenRef.current);
      refreshTokenRef.current = null;
    };
  }, []);
  return (
    <QueryClientProvider client={queryClient}>
      <ThemeProvider theme={MainTheme}>
        <Drawer.Navigator
          initialRouteName={screenName}
          screenOptions={{
            headerShown: Platform.OS === "web" ? false : true,
            drawerType: web ? "permanent" : "slide",
            sceneContainerStyle: { overflow: web ? "visible" : undefined },
            drawerStyle: {
              overflow: web ? "visible" : undefined,
              maxHeight: Platform.OS !== "web" ? undefined : '100vh'
            }
          }}
          drawerContent={DrawerContent}
        >
          <Drawer.Screen
            name="PractitionerNavigator"
            component={PractitionerNavigator}
            initialParams={initialParams}
          />
          <Drawer.Screen
            name="TrialOrganisationSetupScreen"
            component={TrialOrganisationSetupScreen}
          />
          <Drawer.Screen
            name="SendPlanNavigator"
            component={SendPlanNavigator}
            options={{ unmountOnBlur: true }}
          />
          <Drawer.Screen
            name="ConsumerNavigator"
            component={ConsumerNavigator}
            options={{ swipeEnabled: false, gestureEnabled: false }}
          />
          <Drawer.Screen name="ProfileNavigator" component={ProfileNavigator} />
          <Drawer.Screen
            name="OrganisationsNavigator"
            component={OrganisationsNavigator}
          />
          <Drawer.Screen
            name="UsersNavigator"
            component={UsersNavigator}
            initialParams={initialParams}
          />
          <Drawer.Screen
            name="TodoNavigator"
            component={TodoNavigator}
            initialParams={initialParams}
            options={{ swipeEnabled: false, gestureEnabled: false }}
          />
          <Drawer.Screen
            name="LibraryNavigator"
            component={LibraryNavigator}
            options={{ swipeEnabled: false, gestureEnabled: false }}
          />
          <Drawer.Screen
            name="NotificationsNavigator"
            component={NotificationsNavigator}
            initialParams={initialParams}
            options={{ unmountOnBlur: true }}
          />
          <Drawer.Screen
            name="ContentNavigator"
            component={ContentNavigator}
            initialParams={initialParams}
          />
          <Drawer.Screen
            name="BundleNavigator"
            component={BundleNavigator}
            initialParams={initialParams}
            options={{ unmountOnBlur: true }}
          />
          <Drawer.Screen
            name="TagsNavigator"
            component={TagsNavigator}
            initialParams={initialParams}
            options={{ unmountOnBlur: true }}
          />
          <Drawer.Screen
            name="ContactsNavigator"
            component={ContactsNavigator}
            initialParams={initialParams}
            options={{ unmountOnBlur: true }}
          />
          <Drawer.Screen
            name="ActivityNavigator"
            component={ActivityNavigator}
            initialParams={initialParams}
            options={{ unmountOnBlur: true }}
          />
        </Drawer.Navigator>
      </ThemeProvider>
    </QueryClientProvider>
  )
    ;
});

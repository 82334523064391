import React from 'react';
import { View } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { Result } from '../../../components';
import { useAtom, isWebAtom } from '../../../atoms';

export default function VerificationSuccessfulScreen({ navigation }) {
  const web = useAtom(isWebAtom);
  return (
    <View style={{ flex: 1, alignItems: 'center', paddingHorizontal: 40, backgroundColor: 'white' }}>
      <View style={{ maxWidth: web ? 600 : 320 }}>
        <Result
          icon={<Ionicons name="checkmark-circle" size={80} color="green" style={{ marginBottom: 20, marginTop: 100 }} />}
          title="Verified"
          description={`Congratulations! The phone number has been successfully linked to your profile`}
          onPressOk={() => navigation.goBack()}
        />
      </View>
    </View>
  );
}

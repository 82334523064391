import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Platform,
  TouchableOpacity,
  StyleSheet,
} from "react-native";
import { get } from "lodash";
import { format } from "date-fns";
import { optionsBack } from "../../../headerUtils";
import { HeaderButton, MainScreen, TitleText } from "../../../components";
import { BreadCrumb } from "../../../containers";

import {
  setPdfId,
  userByIdAtom,
  useAtom,
  currentUserAtom,
  isWebAtom,
} from "../../../atoms";
import { nameTitle, interpolate, logEvent } from "../../../utils";
import { screen, containerWithoutLoader } from "../../../reactUtils";
import {
  getContactActivity,
  getActivityByList,
  getSingleDistributionContact,
} from "../../../graphql";
import { useInfiniteQuery } from "react-query";

import List from "../../../new/components/List";

export const transformSubmissionObject = (item) => {
  const answers =
    item.submission != null && item.submission.answers
      ? JSON.parse(item.submission.answers)
      : {};
  const form =
    item.submission != null && item.submission.staticAdvice.form
      ? JSON.parse(item.submission.staticAdvice.form)
      : {};
  const data = [];
  if (item.submission != null && item.submission.updatedAt) {
    form.pages.forEach((page) => {
      if (page.show) {
        const result = interpolate(page.show, {
          ...answers,
          completed: true,
        });
        if (result === "false") {
          return;
        }
      }
      page.questions.forEach((question) => {
        if (question.id) {
          let ans = answers[question.id];
          if (question.type === "radio") {
            ans = question.choices
              .filter((choice) =>
                Array.isArray(ans) ? ans.includes(choice.id) : ans === choice.id
              )
              .map((selectedChoices) => selectedChoices.name);
          }
          data.push([question.shortName || question.title, ans]);
        }
      });
    });
  }
  const results = form?.results || [];

  return {
    ...item,
    title: item.submission != null ? form.title : "",
    answers: item.submission != null ? answers : [],
    tableResults: item.submission != null ? data : [],
    results:
      item.submission != null && item.submission.updatedAt
        ? results.map((result) => ({
            label: result.label,
            value: interpolate(result.value, answers),
          }))
        : [],
  };
};

const Field = ({ fontSize, title, date, value, borderWidth, ...props }) => {
  const web = useAtom(isWebAtom);
  const padding = web ? 20 : 13;

  return (
    <>
      <View
        style={{
          flex: 1,
          flexDirection: "row",
          paddingTop: padding,
          backgroundColor: "white",
          alignItems: "center",
          justifyContent: "flex-start",
          paddingHorizontal: 18,
        }}
        {...props}
      >
        <Text
          numberOfLines={1}
          style={{ fontSize: fontSize || 15, paddingRight: 8 }}
        >
          {title}
        </Text>
      </View>

      <View
        style={{
          flex: 1,
          flexDirection: "row",

          paddingBottom: padding - 5,
          backgroundColor: "white",
          alignItems: "center",
          justifyContent: "flex-start",
          paddingHorizontal: 18,
          borderBottomWidth: borderWidth,
          borderBottomColor: "#C6C6C8",
        }}
        {...props}
      >
        <View style={{ justifyContent: "flex-start", width: "50%" }}>
          <Text
            style={{
              fontSize: fontSize || 14,
              paddingRight: 8,
              color: "#aaaaaa",
              opacity: 1,
            }}
          >
            {date}
          </Text>
        </View>
        <View
          style={{
            flex: 1,
            alignItems: "flex-end",
            justifyContent: "flex-end",
          }}
        >
          {typeof value === "string" ? (
            <Text style={{ fontSize: fontSize || 16, paddingLeft: 8 }}>
              {value}
            </Text>
          ) : (
            value
          )}
        </View>
      </View>
    </>
  );
};

function getContact(contactId) {
  let contact = {};

  getSingleDistributionContact({ id: contactId }).then((e) => {
    Object.assign(contact, e.data.distributionContact);
  });
  return contact;
}

const ActivityScreen = screen(({ navigation: { navigate }, route }) => {
  const web = useAtom(isWebAtom);

  const { contactId, name } = route.params || {};

  let sendContact = getContact(contactId);

  // 66932a47-6a0e-4865-a547-2fb39f1d226a - contact ID for me

  const apiFn = (props) =>
    getContactActivity({ id: route.params.contactId, ...props });

  const pathFn = (result) => {
    const resultArr = result.data.contact.content;
    return {
      ...resultArr,
      items: resultArr.items.map((e) => transformSubmissionObject(e)),
    };
  };

  const {
    data: pageListData,
    fetchNextPage: listLoadMore,
    hasNextPage: listHasMore,
    isFetchingNextPage: isListFetchingNextPage,
    isLoading: isListLoading,
  } = useInfiniteQuery(
    [contactId, "activity"],
    ({ pageParam }) => apiFn(pageParam).then(pathFn),
    {
      getNextPageParam: (lastPage) =>
        lastPage.nextToken ? { nextToken: lastPage.nextToken } : undefined,
    }
  );

  const listLoading = isListLoading || isListFetchingNextPage;

  const loading = listLoading;

  const [searchText, setSearchText] = useState("");

  const data = pageListData ? pageListData.pages.flatMap((x) => x.items) : [];

  if (data.length < 50 && !listLoading && listHasMore) {
    listLoadMore();
  }

  function parseISOString(s) {
    var b = s.split(/\D+/);
    return new Date(Date.UTC(b[0], --b[1], b[2], b[3], b[4], b[5], b[6]));
  }

  const renderItem = ({ item, index }) => {
    const result =
      item.submission != null
        ? item.results[0] || {
            label: item.submission.updatedAt ? "Completed" : "Not Completed",
            value: "",
          }
        : { label: "", value: "" };
    const submissionDate = item.submission?.updatedAt ?
    parseISOString(item.submission.updatedAt) : null;

    return (
      <>
        <TouchableOpacity
          onPress={() => {
            if (item.submission != null && item.submission.updatedAt) {
              logEvent("form_results_viewed", {
                id: item.id,
              });
              navigate("ResultsScreen", {
                title: item.submission.staticAdvice.displayName,
                updatedAt: item.submission.updatedAt,
                results: item.results,
                pdfDownloadUrl: item.submission.pdfDownloadUrl,
                tableResults: item.tableResults,
              });
            } else {
              setPdfId(item.content.id);
              navigate("PdfScreen", {
                id: item.content.id,
              });
            }
          }}
        >
          <Field
            title={item.content.displayName}
            borderWidth={index === data.length - 1 ? 1 : 1}
            date={format(
              new Date(item.sentAt ? item.sentAt * 1000 : null),
              "dd/MM/yyyy"
            )}
            value={
              <View
                style={{
                  flex: 1,
                  justifyContent: "flex-end",
                }}
              >
                {submissionDate &&
                  <Text
                    style={{
                      fontSize: 14,
                      textAlign: "right",
                      color: "#aaaaaa",
                      opacity: 1,
                    }}
                  >
                    completed {format(submissionDate, 'dd/MM/yyyy')}
                  </Text>
                }

                <Text style={{ textAlign: "right", fontSize: 14, color: "#426af3" }}>
                  {result.label + " " + result.value}
                </Text>
              </View>
            }
          />
        </TouchableOpacity>
      </>
    );
  };

  return (
    <>
      <MainScreen backgroundColor="#FFFFFF" style={{ flex: 1 }}>
        <View style={styles.nameContainer}>
          <TitleText
            lineHeight={40}
            fontWeight="500"
            style={{ alignItems: "flex-start" }}
          >
            {name}
          </TitleText>

          <HeaderButton
            accessibilityLabel={Platform.select({
              android: "Share Content",
            })}
            style={{
              borderWidth: 1,
              borderRadius: 25,
            }}
            title="Share Content"
            onPress={() => {
              navigate("SendPlanNavigator", {
                screen: "SendScreen",
                params: {
                  selectedContacts: [sendContact],
                },
              });
            }}
          />
        </View>

        <List
          setSearchText={(casedSearch) =>
            setSearchText(casedSearch.toLowerCase())
          }
          flatListParams={{
            data: data.filter((i) =>
              i.content.displayName.toLowerCase().includes(searchText)
            ),
            renderItem,
            onEndReached: listLoadMore,
          }}
          loading={loading}
        />
      </MainScreen>
    </>
  );
});

const ActivityScreenTitle = containerWithoutLoader(({ route }) => {
  const { name } = route.params || {};

  return <BreadCrumb parts={["currentOrganisation.name", "Activity", name]} />;
});

export const OptionsBack = (web) => optionsBack(web, ActivityScreenTitle);

const styles = StyleSheet.create({
  nameContainer: {
    backgroundColor: "white",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 21,
    alignItems: "center",
    paddingVertical: 10,
    borderBottomColor: "#AAAAAA",
    borderBottomWidth: 1,
    width: "100%",
  },
});

export default ActivityScreen;

import React, { useState } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { format, differenceInMilliseconds } from "date-fns";
import {
  MainScreen,
  Button,
  TextInput,
  HeaderButton,
  ErrorMessage,
  PaginatedList,
  CheckBox,
  Modal,
  FieldLabel,
} from "../../components";
import { createStackNavigator } from "@react-navigation/stack";
import {
  useGlobal,
  useForm,
  useListOrganisation,
  useHasAnyRoles,
} from "../../hooks";
import { changeOrg, testId } from "../../utils";
import {
  createOrganisation,
  updateOrganisation,
  updateUserOrganisationTrial,
} from "../../graphql";
import { options } from "../../headerUtils";
import { screen } from "../../reactUtils";
import { setCurrentOrganisation, useAtom, isWebAtom, atom } from "../../atoms";

const showModalAtom = atom(false);
const setShowModal = (v) => showModalAtom.update(v);

const OrganisationsScreen = screen(({ navigation }) => {
  const web = useAtom(isWebAtom);
  const showModal = useAtom(showModalAtom);
  const [, updateData] = useGlobal();
  const hasSystemAdmin = useHasAnyRoles(["system_admin"]);
  const hasSupport = useHasAnyRoles(["support"]);
  const [selectedOrganisation, setSelectedOrganisation] = useState(null);

  const getExpiry = (item) => {
    if (item.licenses.items.length === 0) {
      return "Full License";
    }
    const activeLicense = item.licenses.items.find((license) => license.active);
    if (activeLicense) {
      if (activeLicense.expiresOn) {
        const isExpired =
          differenceInMilliseconds(
            new Date(activeLicense.expiresOn),
            new Date()
          ) < 0;
        return isExpired
          ? "Expired"
          : format(new Date(activeLicense.expiresOn), "dd/MM/yyyy");
      }
      return "Full License";
    }
    return "Expired";
  };

  return (
    <MainScreen
      backgroundColor="white"
      hasAuth={hasSystemAdmin || hasSupport}
      isList={true}
    >
      <PaginatedList
        displayAs={web ? "web" : null}
        useList={useListOrganisation}
        formatList={(organizations) =>
          organizations.map((item) => ({ ...item, roles: [] }))
        }
        emptyTitle="You don't have any organisations"
        emptyDescription="Please click on the + icon to add one."
        columnConfig={[
          [
            { width: "60%", text: "Name" },
            { width: "25%", text: "Expires" },
            {
              width: "15%",
              text: "Actions",
              style: { justifyContent: "center" },
            },
          ],
        ]}
        onRowPress={(item) => {
          setCurrentOrganisation(item);
          changeOrg(updateData, item);
          navigation.navigate("UsersNavigator");
        }}
        rowConfig={[
          { width: "60%", text: (item) => item.name },
          { width: "25%", text: (item) => getExpiry(item) },
          {
            width: "15%",
            render: (item) => {
              return (
                <View style={{ flexDirection: "row" }}>
                  <TouchableOpacity
                    onPress={() => {
                      setSelectedOrganisation(item);
                      setShowModal(true);
                    }}
                  >
                    <Ionicons name="create" size={24} color="#5386A5" />
                  </TouchableOpacity>
                </View>
              );
            },
            style: { justifyContent: "center" },
          },
        ]}
        renderItem={({ item }) => (
          <View
            {...testId(`${item.name}`)}
            style={{
              flexDirection: "row",
              justifyContent: "space-between",
              borderBottomColor: "#c6c6c8",
              borderBottomWidth: 1,
              padding: 20,
            }}
          >
            <Text style={{ fontSize: 16, color: "#000000" }}>{item.name}</Text>
            <Text
              style={{
                marginLeft: 20,
                fontSize: 16,
                color: "#000000",
                justifyContent: "flex-end",
              }}
            >
              {getExpiry(item)}
            </Text>
          </View>
        )}
      />
      {showModal && (
        <Modal
          isVisible={showModal}
          title={
            selectedOrganisation
              ? "Update Organisation"
              : "Create a new Organisation"
          }
          onClose={() => {
            setSelectedOrganisation(null);
            setShowModal(false);
          }}
          style={{ maxWidth: 400 }}
        >
          <CreateUpdateOrganisationModal
            selectedOrganisation={selectedOrganisation}
          />
        </Modal>
      )}
    </MainScreen>
  );
});

const CreateUpdateOrganisationModal = ({ selectedOrganisation, onClose }) => {
  const [data, updateData] = useGlobal();
  const [contentRestriction, setContentRestriction] = useState(
    selectedOrganisation ? selectedOrganisation.contentRestriction : false
  );
  const isTrialOrganisation = !!selectedOrganisation?.messageThrottle;

  const { onSubmit, isSubmitting, control, errors, error } = useForm({
    initial: {
      name: selectedOrganisation?.name || "",
      limit: selectedOrganisation?.messageThrottle?.limit || null,
    },
    schema: (yup) => ({
      name: yup.string().required("Please enter the name of the organisation"),
    }),
    onSubmit: async ({ name, limit }) => {
      if (selectedOrganisation) {
        if (isTrialOrganisation) {
          const res = await updateUserOrganisationTrial({
            input: {
              organisationId: selectedOrganisation.id,
              contentRestriction: !!contentRestriction,
              messageThrottle: {
                limit: limit,
                frequency: "monthly",
              },
            },
          });
          updateData((draft) => {
            draft.allOrganisations.items = draft.allOrganisations.items.map(
              (item) => {
                return item.id ===
                  res.data.updateUserOrganisationTrial.organisation.id
                  ? {
                      ...item,
                      ...res.data.updateUserOrganisationTrial.organisation,
                    }
                  : item;
              }
            );
          });
        } else {
          const res = await updateOrganisation({
            input: {
              id: selectedOrganisation.id,
              name: name,
              contentRestriction: !!contentRestriction,
            },
          });
          updateData((draft) => {
            draft.allOrganisations.items = draft.allOrganisations.items.map(
              (item) => {
                return item.id === res.data.updateOrganisation.id
                  ? { ...item, ...res.data.updateOrganisation }
                  : item;
              }
            );
          });
        }
      } else {
        const res = await createOrganisation({
          input: {
            name: name,
            contentRestriction: contentRestriction,
          },
        });
        updateData((draft) => {
          draft.allOrganisations.items.unshift(res.data.createOrganisation);
        });
      }
      onClose();
    },
  });

  return (
    <View>
      <View>
        <FieldLabel>Name</FieldLabel>
        <TextInput name="name" errors={errors} control={control} />
      </View>
      {isTrialOrganisation && (
        <View>
          <FieldLabel>Message throttle limit (Monthly)</FieldLabel>
          <TextInput name="limit" errors={errors} control={control} />
        </View>
      )}
      <View
        style={{ flexDirection: "row", marginTop: 20, alignItems: "center" }}
      >
        <View style={{ marginTop: 0 }}>
          <CheckBox
            checked={contentRestriction}
            onPress={() => setContentRestriction((restriction) => !restriction)}
          />
        </View>
        <Text style={{ marginLeft: 10 }}>Limit available content</Text>
      </View>
      <ErrorMessage err={error} />
      <View
        style={{
          marginTop: 60,
          justifyContent: "center",
          flexDirection: "row",
        }}
      >
        <Button loading={isSubmitting} invert={true} onPress={onSubmit}>
          {selectedOrganisation ? "Update" : "Create"}
        </Button>
      </View>
    </View>
  );
};

const Stack = createStackNavigator();

export default () => (
  <Stack.Navigator>
    <Stack.Screen
      name="OrganisationsScreen"
      component={OrganisationsScreen}
      options={options("Organisations", ({ route }) => (
        <HeaderButton
          icon="add-circle-outline"
          title="Create Organisation"
          onPress={() => setShowModal(true)}
        />
      ))}
    />
  </Stack.Navigator>
);

import React from 'react';
import { View, Text, TouchableOpacity, Platform } from 'react-native';
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';

export default function SwipeActionBar({ onEditPress, onRemovePress }) {
  return (
    <View
      style={{
        flex: 1,
        flexDirection: 'row',
        justifyContent: 'flex-end',
        borderBottomColor: '#c6c6c8',
        borderBottomWidth: 1,
      }}
    >
      <TouchableOpacity
          testID = {'Edit'}
          accessibilityLabel = {Platform.select({android: 'Edit'})}
        style={{ width: 90, alignItems: 'center', justifyContent: 'center', padding: 0, backgroundColor: '#FC9500' }}
        onPress={onEditPress}
      >
        <MaterialCommunityIcons name="pencil" size={18} color="white" />
        <Text style={{ color: 'white', marginTop: 3 }}>Edit</Text>
      </TouchableOpacity>
      <TouchableOpacity
          testID = {'Remove'}
          accessibilityLabel = {Platform.select({android: 'Remove'})}
        style={{ width: 90, alignItems: 'center', justifyContent: 'center', padding: 0, backgroundColor: '#FB3E30' }}
        onPress={onRemovePress}
      >
        <Ionicons name="trash" size={18} color="white" />
        <Text style={{ color: 'white', marginTop: 3 }}>Remove</Text>
      </TouchableOpacity>
    </View>
  );
}

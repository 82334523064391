import React from "react";
import {
  View,
  ScrollView,
  Text,
  TouchableOpacity,
  Platform,
} from "react-native";
import { FieldLabel } from "./Modal";
import TextInput from "./TextInput";
import Button from "./Button";
import Tag from "./Tag";
import { useForm, Controller } from "react-hook-form";
import { Ionicons } from "@expo/vector-icons";
import { useMutation } from "react-query";
import Menu, {
  MenuOptions,
  MenuOption,
  MenuTrigger,
  renderers,
} from "react-native-popup-menu";
import { ErrorMessage } from "@hookform/error-message";

import DatePicker from "./DatePicker";
import styled from "@emotion/native";

import { createContent, putSignedUrl } from "../graphql";
import { isWebAtom, useAtom } from "../atoms";

import * as DocumentPicker from "expo-document-picker";
import {
  PATIENT_CONTENT_TAGS,
  sanitizeFilename,
  transformPatientContentTag,
  CONDITIONS,
  IOWNAError,
} from "../utils";
import SelectFileButton from "../new/components/SelectFileButton";

const CheckedOption = ({ value, checked, text, onSelect }) => (
  <TouchableOpacity
    style={{ paddingTop: 15, paddingBottom: 15, flex: 1 }}
    onPress={onSelect}
  >
    <View
      style={{
        flexDirection: "row",
      }}
    >
      <Text style={{ flex: 1 }}>{checked ? "\u2713   " : "    "}</Text>
      <Text style={{ flex: 6 }}>{text}</Text>
    </View>
  </TouchableOpacity>
);

const SelectTags = ({ tags, value = [], onChange }) => {
  const keyedTags = Object.fromEntries(
    tags.map((tag) => [tag.value, tag.label])
  );
  return (
    <Menu>
      <MenuTrigger>
        <View style={{ flexDirection: "row" }}>
          <Ionicons name="add-circle-outline" size={24} />
        </View>
        <View style={{ flexDirection: "row", flexWrap: "wrap" }}>
          {value.map((tagValue) => {
            const tagLabel = keyedTags[tagValue];
            return <Tag key={tagValue} text={tagLabel} />;
          })}
        </View>
      </MenuTrigger>
      <MenuOptions customStyles={optionsStyles}>
        <ScrollView style={{ maxHeight: 400, top: 0 }}>
          {tags.map((tag) => (
            <CheckedOption
              key={`${tag.value}`}
              checked={value.includes(tag.value)}
              onSelect={() => {
                onChange(
                  value.includes(tag.value)
                    ? value.filter((prevTag) => prevTag !== tag.value)
                    : [...value, tag.value]
                );
              }}
              text={tag.label}
            />
          ))}
        </ScrollView>
      </MenuOptions>
    </Menu>
  );
};

const HelpButton = ({ text }) => {
  return (
    <>
      <View>
        <Menu renderer={renderers.Popover} rendererProps={{ placement: "top" }}>
          <MenuTrigger customStyles={triggerStyles}>
            <Ionicons size={24} name="help-circle-outline"></Ionicons>
          </MenuTrigger>
          <MenuOptions customStyles={helpStyles}>
            <MenuOption text={text}></MenuOption>
          </MenuOptions>
        </Menu>
      </View>
    </>
  );
};

const FileUploadInput = ({ value, onChange }) => (
  <>
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        flexWrap: "wrap",
      }}
    >
      <SelectFileButton
        title="select file"
        onPress={() =>
          DocumentPicker.getDocumentAsync({
            //copyToCacheDirectory set to true allows the expo-file-system to read the file immediately after it is picked
            copyToCacheDirectory: true,
            type: ["application/pdf", "image/jpeg", "image/jpg", "image/png"],
          }).then((result) => {
            //result object comes back differently from mobile document picker and web document picker. If there is a result.file (web) then file = result.file otherwise we send result
            onChange(result.file != undefined ? result.file : result);
          })
        }
      ></SelectFileButton>
      <Text style={{ paddingRight: 10 }}>{value?.name}</Text>
    </View>
  </>
);

const FieldLabelWithIcon = styled.Text`
  font-size: 16;
  font-weight: 500;
  color: ${(props) => props.theme.text};
`;

export default function ({ onClose, onSave }) {
  const web = useAtom(isWebAtom);

  const { control, handleSubmit, formState: {errors}, clearErrors } = useForm({
    defaultValues: {
      title: "",
      file: "",
      patientConditionTags: [],
      patientContentTags: [],
      date: new Date(),
    },
  });

  const mutation = useMutation(
    ({ file, title, patientContentTags, patientConditionTags, date }) => {
      const tags = patientContentTags.concat(patientConditionTags);
      const createdDate = date.toISOString();

      const uploadFile = file;
      const filename = sanitizeFilename(uploadFile.name);
      const ext = filename.split(".").pop();
      if (!["pdf", "jpg", "png", "jpeg"].includes(ext)) {
        throw new IOWNAError("You can only upload a PDF or JPG/PNG file");
      }
      const contentType =
        ext === "pdf" ? "application/pdf" : "jpg" ? "image/jpg" : "jpeg" ? "image/jpeg" :"image/png";

      return putSignedUrl({
        input: {
          filename: filename,
          acceptedCopyrightStatement: true,
          contentType,
        },
      })
        .then((res) => {
          const putSignedRes = res.data.putSignedUrl;
          const metadata = JSON.parse(putSignedRes.metadata);
          return fetch(putSignedRes.url, {
            method: "PUT",
            body: file,
            headers: {
              "Content-Type": contentType,
              "Content-Disposition": `attachment; filename="${filename}"`,
              "x-amz-meta-acceptedcopyrightstatementat":
                metadata["acceptedCopyrightStatementAt"],
              "x-amz-meta-filename": metadata["filename"],
              "x-amz-meta-uploadedby": metadata["uploadedBy"],
            },
          }).then((res) => {
            return putSignedRes;
          });
        })
        .then((putSignedUrl) => {
          return createContent({
            input: {
              name: title.trim(),
              fileName: putSignedUrl.key.slice(1),
              tags: tags,
              createdAt: createdDate,
            },
          });
        })
        .then((res) => {
          onClose();
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  );

  //console.log("Errors", errors);

  return (
    <View>
      <View>
        <Controller
          control={control}
          name="file"
          render={({field: {onChange, value}}) => (
            <FileUploadInput value={value} onChange={onChange} />
          )}
          rules={{ required: true }}
        />
      </View>

      {mutation.isError ? (
        <Text style={{ color: "red", marginTop: 10 }}>
          An error occurred: {mutation.error.message}
        </Text>
      ) : null}

      <ErrorMessage
        errors={errors}
        name="file"
        message="Please upload a document in pdf, PNG or JPEG format"
        as={<Text style={{ color: "red" }} />}
      />
      <View
        style={{ flexDirection: "row", alignItems: "center", marginTop: 30 }}
      >
        <FieldLabelWithIcon>Title</FieldLabelWithIcon>
        <HelpButton
          text="Suggested structure of title: Clinician’s name - document name

e.g. ‘Dr Smith - X-ray Scan Results’"
        ></HelpButton>
      </View>

      <TextInput
        name="title"
        width="100%"
        control={control}
        errors={errors}
        rules={{ required: "A title is required" }}
      />

      <View
        style={{
          flexDirection: "row",
          alignItems: "center",
          marginTop: 30,
        }}
      >
        <FieldLabelWithIcon>Content Type</FieldLabelWithIcon>
        <HelpButton text="If you are unsure what category your piece of content falls under please select 'Other information'"></HelpButton>
      </View>
      <Controller
        control={control}
        name="patientContentTags"
        render={({field: {onChange, value}}) => (
          <SelectTags
            tags={PATIENT_CONTENT_TAGS.map((tag) => ({
              value: tag,
              label: transformPatientContentTag(tag),
            }))}
            value={value}
            onChange={onChange}
          />
        )}
        rules={{
          validate: (value) => {
            return value.length > 0;
          },
        }}
      />

      <ErrorMessage
        errors={errors}
        name="patientContentTags"
        message="Please select the content type"
        as={<Text style={{ color: "red" }} />}
      />
      <View
        style={{ flexDirection: "row", alignItems: "center", marginTop: 30 }}
      >
        <FieldLabelWithIcon>Condition</FieldLabelWithIcon>
        <HelpButton text="If you are unsure what condition your piece of content falls under please select 'General Medicine'"></HelpButton>
      </View>
      {/* conditions list is alphabetical - sort function */}
      <Controller
        control={control}
        name="patientConditionTags"
        render={({field: {onChange, value}}) => (
          <SelectTags
            tags={CONDITIONS.sort().map((tag) => ({ value: tag, label: tag }))}
            value={value}
            onChange={onChange}
          />
        )}
        rules={{
          validate: (value) => {
            return value.length > 0;
          },
        }}
      />
      {
        <ErrorMessage
          errors={errors}
          name="patientConditionTags"
          message="Please select the condition"
          as={<Text style={{ color: "red", marginTop: 2 }} />}
        />
      }

      {/* layout view different for web and mobile */}
      <View
        style={{
          flexDirection: "row",
          width: "100%",
        }}
      >
        <FieldLabel style={{ flex: 1 }}>Date of Document</FieldLabel>

{/** in order for the datepicker to line up properly the flex for this view is different on all three platforms which is why the ternary looks like this! */}
        <View
          style={{
            flex: Platform.OS === "ios" ? 2 : Platform.OS === "android" ? 1 : 'initial',
            justifyContent: "flex-end",
            alignItems: Platform.OS === "android" ? "flex-end" : "stretch",
          }}
        >

    
      
          <Controller
            control={control}
            name="date"
            render={({field: {onChange, value}}) => (
              <DatePicker
                value={value}
                onChange={(date) => {
                  // console.log(typeof date, date);
                  onChange(date);
                }}
              />
            )}
          />
        </View>
      </View>

      <Text style={{ marginTop: 30, fontSize: 11 }}>
        By submitting these documents you acknowledge that they will be
        processed, stored and used by us and will be subject to the protections,
        as set out in the Patient Terms and Conditions and Patient Privacy
        Notice.
      </Text>
      <View
        style={{
          marginTop: 40,
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Button
          loading={mutation.isLoading}
          onPress={handleSubmit(mutation.mutate)}
        >
          Upload
        </Button>
      </View>
    </View>
  );
}

const optionsStyles = {
  optionsContainer: {
    padding: 15,
    borderRadius: 5,
  },
  optionsWrapper: {},
  optionWrapper: {
    backgroundColor: "yellow",

    margin: 5,
  },
  optionTouchable: {
    underlayColor: "gold",
    activeOpacity: 70,
  },
  optionText: {
    color: "black",
  },
};

const helpStyles = {
  optionsContainer: {
    minWidth: 200,
  },
};

const triggerStyles = {
  triggerOuterWrapper: {
    padding: 5,
    flex: 1,
  },
};

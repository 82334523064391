import React, { useState } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import {
  Menu,
  MenuOptions,
  MenuTrigger,
  renderers,
} from "react-native-popup-menu";
import { createStackNavigator } from "@react-navigation/stack";
import { Ionicons } from "@expo/vector-icons";
import { orderBy } from "lodash";
import {
  MainScreen,
  Button,
  Tag,
  Modal,
  FieldLabel,
  TextInput,
  CheckBox,
  HeaderButton,
  ErrorMessage,
  PaginatedList,
  TabButton,
} from "../../../components";
import { BreadCrumb } from "../../../containers";
import { createBottomTabNavigator } from "@react-navigation/bottom-tabs";
import { options, optionsBack } from "../../../headerUtils";
import {
  useForm,
  useGlobal,
  useUserRoles,
  useRoleInvites,
  useHasAnyRoles,
} from "../../../hooks";
import {
  ALL_ROLES,
  transformRole,
  handleError,
  IOWNAError,
} from "../../../utils";
import {
  confirm,
  currentOrganisationAtom,
  useAtom,
  isWebAtom,
  atom,
} from "../../../atoms";
import {
  addRole,
  removeRole,
  createOrganisationRoleInvite,
  deleteOrganisationRoleInvite,
} from "../../../graphql";
import PeopleOutline from "../../../assets/icons/people-outline.svg";
import PersonAddOutline from "../../../assets/icons/person-add-outline.svg";
import { screen } from "../../../reactUtils";

const showModalAtom = atom(false);
const setShowModal = (v) => showModalAtom.update(v);

const CheckedOption = ({ value, checked, text, onSelect }) => (
  <TouchableOpacity
    style={{ paddingTop: 20, paddingBottom: 20 }}
    onPress={onSelect}
  >
    <Text>{(checked ? "\u2713   " : "    ") + text}</Text>
  </TouchableOpacity>
);

const RolesItem = ({ item, addRole, removeRole }) => {

  return (
    <Menu renderer={renderers.Popover}>
      <MenuTrigger>
        <View style={{ flex: 1, flexDirection: "row", flexWrap: "wrap" }}>
          {item.roles.length === 0 ? (
            <Ionicons name="add-circle-outline" size={24} />
          ) : null}
          {item.roles.map((role) => (
            <Tag key={role} text={transformRole(role)} />
          ))}
        </View>
      </MenuTrigger>
      <MenuOptions
        // style={{ width: 200, padding: 10 }}
        customStyles={{ optionsWrapper: { width: 200 } }}
      >
        {ALL_ROLES.map((role) => {
          const checked = item.roles.includes(role);
          return (
            <CheckedOption
              key={`${item.id}_${role}`}
              checked={checked}
              onSelect={() => {
                if (checked) {
                  removeRole(item.id, role);
                } else {
                  addRole(item.id, role);
                }
              }}
              text={transformRole(role)}
            />
          );
        })}
      </MenuOptions>
    </Menu>
  );
};

const UsersScreen = screen(({ navigation, children }) => {
  const hasAuth = useHasAnyRoles([
    "system_admin",
    "organisation_owner",
    "organisation_admin",
    "support",
  ]);
  const showModal = useAtom(showModalAtom);

  return (
    <MainScreen backgroundColor="#F2F2F7" hasAuth={hasAuth} isList={true}>
      {children}
      {showModal && (
        <Modal
          isVisible={showModal}
          title="Invite a User"
          onClose={() => setShowModal(false)}
          style={{ maxWidth: 400 }}
        >
          <InviteUser organisationId={currentOrganisationAtom.getValue().id} />
        </Modal>
      )}
    </MainScreen>
  );
});

export const OrganisationUsersScreen = ({ navigation }) => (
  <UsersScreen navigation={navigation}>
    <OrganisationUsers />
  </UsersScreen>
);

export const PendingInvitationsScreen = ({ navigation }) => (
  <UsersScreen navigation={navigation}>
    <PendingInvitations />
  </UsersScreen>
);

export const OrganisationUsers = () => {
  const [data, updateData] = useGlobal();
  const web = useAtom(isWebAtom);

  const addUserRole = (id, role, item) => {
    updateData((draft) => {
      draft.userRoles.items.push({
        userId: id,
        role: role,
        user: {
          firstName: item.firstName,
          lastName: item.lastName,
        },
      });
      addRole({
        input: {
          userId: id,
          organisationId: currentOrganisationAtom.getValue().id,
          role: role,
        },
      });
    });
  };

  const removeUserRole = (id, role) => {
    updateData((draft) => {
      draft.userRoles.items = draft.userRoles.items.filter(
        (user) => !(user.userId === id && user.role === role)
      );
      removeRole({
        input: {
          userId: id,
          organisationId: currentOrganisationAtom.getValue().id,
          role: role,
        },
      });
    });
  };

  const formatUsers = (userRoles) => {
    const usersMap = {};
    userRoles.forEach((u) => {
      const key = u.userId;
      const displayName = [];
      if (u.user.firstName && u.user.firstName.length > 1)
        displayName.push(u.user.firstName);
      if (u.user.lastName && u.user.lastName.length > 1)
        displayName.push(u.user.lastName);
      if (displayName.length == 0) displayName.push(u.user.email);
      usersMap[key] = usersMap[key] || {
        id: key,
        firstName: u.user.firstName || "",
        lastName: u.user.lastName || "",
        displayName: displayName.join(" "),
        roles: [],
      };
      usersMap[key].roles.push(u.role);
    });
    return orderBy(
      Object.keys(usersMap).map((key) => usersMap[key]),
      [(user) => (user.firstName ? user.firstName.toLowerCase() : "")],
      ["asc"]
    );
  };

  //renderItem is mobile

  return (
    <PaginatedList
      displayAs={web ? "web" : null}
      limit={10000}
      useList={useUserRoles}
      formatList={formatUsers}
      emptyTitle="You don't have any users"
      emptyDescription="Please click on the + icon to create one now."
      columnConfig={[
        [
          { width: "25%", text: "Name" },
          { width: "75%", text: "Roles" },
        ],
      ]}
      rowConfig={[
        { width: "25%", text: (item) => item.displayName },
        {
          width: "75%",
          text: (item) => {
            return (
              <RolesItem
                item={item}
                addRole={(id, role) => addUserRole(id, role, item)}
                removeRole={removeUserRole}
              />
            );
          },
        },
      ]}
      renderItem={({ item }) => (
        <View
          style={{
            backgroundColor: "white",
            borderBottomColor: "#c6c6c8",
            borderBottomWidth: 1,
          }}
        >
          <View style={{ flexDirection: "row" }}>
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                paddingTop: 10,
                paddingBottom: 10,
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              <Text style={{ fontSize: 16, color: "#000000" }}>
                {item.displayName}
              </Text>
            </View>
          </View>
          <View
            style={{
              flex: 1,
              width: "90%",
              flexDirection: "row",
              paddingBottom: 10,
              marginLeft: 20,
              marginRight: 20,
            }}
          >
            <RolesItem
              item={item}
              addRole={(id, role) => addUserRole(id, role, item)}
              removeRole={removeUserRole}
            />
          </View>
        </View>
      )}
    />
  );
};

export const PendingInvitations = () => {
  const [, updateData] = useGlobal();
  const web = useAtom(isWebAtom);

  const addInvitationRole = async (email, role) => {
    const res = await createOrganisationRoleInvite({
      input: {
        organisationId: currentOrganisationAtom.getValue().id,
        email,
        role,
      },
    });
    updateData((draft) => {
      draft.roleInvites.items.push({
        id: res.data.createOrganisationRoleInvite.id,
        email,
        role,
      });
    });
  };
  const removeInvitationRole = async (item, role) => {
    const roleIndex = item.roles.indexOf(role);
    const id = item.ids[roleIndex];
    updateData((draft) => {
      draft.roleInvites.items = draft.roleInvites.items.filter(
        (invite) => invite.id !== id
      );
      deleteOrganisationRoleInvite({ id: id });
    });
  };

  const onRemoveInvitePress = confirm(
    "Remove Invitation",
    "Are you sure you want to remove this invitation?",
    handleError(async (item) => {
      updateData((draft) => {
        draft.roleInvites.items = draft.roleInvites.items.filter(
          (invite) => !(invite.email === item.email)
        );
      });
      for (const id of item.ids) {
        await deleteOrganisationRoleInvite({ id: id });
      }
    })
  );

  const formatInvites = (roleInvites) => {
    const invitationsMap = {};
    roleInvites.forEach((invite) => {
      const key = invite.email;
      invitationsMap[key] = invitationsMap[key] || {
        id: key,
        email: key,
        ids: [],
        roles: [],
      };
      invitationsMap[key].ids.push(invite.id);
      invitationsMap[key].roles.push(invite.role);
    });
    return orderBy(
      Object.keys(invitationsMap).map((key) => invitationsMap[key]),
      [(invite) => invite.email.toLowerCase()],
      ["asc"]
    );
  };
  return (
    <PaginatedList
      displayAs={web ? "web" : null}
      limit={10000}
      useList={useRoleInvites}
      formatList={formatInvites}
      emptyTitle="You don't have any pending invites in this organisation yet"
      emptyDescription="Please invite a user"
      columnConfig={[
        [
          { width: "40%", text: "Email" },
          { width: "40%", text: "Roles" },
          {
            width: "20%",
            text: "Actions",
            style: { justifyContent: "center" },
          },
        ],
      ]}
      rowConfig={[
        { width: "40%", text: (item) => item.email },
        {
          width: "40%",
          render: (item) => (
            <RolesItem
              item={item}
              addRole={addInvitationRole}
              removeRole={(email, role) => removeInvitationRole(item, role)}
            />
          ),
        },
        {
          width: "20%",
          render: (item) => (
            <TouchableOpacity onPress={() => onRemoveInvitePress(item)}>
              <Ionicons name="trash" size={24} color="#5386A5" />
            </TouchableOpacity>
          ),
          style: { justifyContent: "center" },
        },
      ]}
      renderItem={({ item }) => (
        <View
          style={{
            backgroundColor: "white",
            borderBottomColor: "#c6c6c8",
            borderBottomWidth: 1,
          }}
        >
          <View style={{ flex: 1, flexDirection: "row" }}>
            <View
              style={{
                flex: 1,
                flexDirection: "row",
                paddingTop: 20,
                paddingBottom: 10,
                marginLeft: 20,
                marginRight: 20,
              }}
            >
              <View style={{ flex: 1 }}>
                <Text style={{ fontSize: 16, color: "#000000" }}>
                  {item.email}
                </Text>
              </View>
              <TouchableOpacity
                style={{ marginLeft: 20 }}
                onPress={() => onRemoveInvitePress(item)}
              >
                <Ionicons name="trash" size={24} color="#5386A5" />
              </TouchableOpacity>
            </View>
          </View>
          <View
            style={{
              flex: 1,
              width: "90%",
              flexDirection: "row",
              paddingBottom: 10,
              marginLeft: 20,
              marginRight: 20,
            }}
          >
            <RolesItem
              item={item}
              addRole={addInvitationRole}
              removeRole={(email, role) => removeInvitationRole(item, role)}
            />
          </View>
        </View>
      )}
    />
  );
};

const InviteUser = ({ organisationId, onClose }) => {
  const [data, updateData] = useGlobal();
  const [roles, setRoles] = useState([]);
  const addRole = (role) => {
    setRoles(roles.concat([role]));
  };
  const removeRole = (role) => {
    setRoles(roles.filter((it) => it !== role));
  };
  const { onSubmit, isSubmitting, control, errors, error } = useForm({
    initial: {
      email: "",
    },
    schema: (yup) => ({
      email: yup
        .string()
        .required("Please enter the email of the user")
        .email("This is not a valid email"),
    }),
    onSubmit: async ({ email }) => {
      if (roles.length === 0) {
        throw new IOWNAError("You need to assign roles to this user");
      }
      const newInvites = [];
      for (const role of roles) {
        const res = await createOrganisationRoleInvite({
          input: {
            organisationId,
            email,
            role,
          },
        });
        newInvites.push({
          id: res.data.createOrganisationRoleInvite.id,
          email,
          role,
        });
      }
      updateData((draft) => {
        draft.roleInvites.items = [...draft.roleInvites.items, ...newInvites];
      });
      onClose();
    },
  });
  var dividedRoles = [];
  for (var i = 0; i < ALL_ROLES.length; i += 2) {
    dividedRoles.push(ALL_ROLES.slice(i, i + 2));
  }
  return (
    <View>
      <FieldLabel>Email</FieldLabel>
      <TextInput name="email" errors={errors} control={control} />
      <FieldLabel>Roles</FieldLabel>
      <View style={{ flexDirection: "row" }}>
        {dividedRoles.map((sizedRoles, index) => {
          return (
            <View key={`${index}`} style={{ marginLeft: index === 0 ? 0 : 20 }}>
              {sizedRoles.map((role) => {
                const checked = roles.includes(role);
                return (
                  <View
                    key={role}
                    style={{
                      flexDirection: "row",
                      alignItems: "center",
                      marginTop: 20,
                    }}
                  >
                    <CheckBox
                      checked={checked}
                      onPress={() => {
                        if (checked) {
                          removeRole(role);
                        } else {
                          addRole(role);
                        }
                      }}
                    />
                    <Text style={{ marginLeft: 10 }}>
                      {transformRole(role)}
                    </Text>
                  </View>
                );
              })}
            </View>
          );
        })}
      </View>
      <ErrorMessage err={error} />
      <View
        style={{
          marginTop: 60,
          flexDirection: "row",
          justifyContent: "center",
        }}
      >
        <Button loading={isSubmitting} invert={true} onPress={onSubmit}>
          Invite User
        </Button>
      </View>
    </View>
  );
};

const Stack1 = createStackNavigator();
const OrganisationUsersNavigator = () => (
  <Stack1.Navigator>
    <Stack1.Screen
      name="OrganisationUsersScreen"
      component={OrganisationUsersScreen}
      options={options()}
    />
  </Stack1.Navigator>
);

const Stack2 = createStackNavigator();
const PendingInvitationsNavigator = () => (
  <Stack2.Navigator>
    <Stack2.Screen
      name="PendingInvitationsScreen"
      component={PendingInvitationsScreen}
      options={options(null, (props) => (
        <View style={{ flexDirection: "row" }}>
          <HeaderButton
            icon="add-circle-outline"
            title="Invite User"
            onPress={() => setShowModal(true)}
          />
        </View>
      ))}
    />
  </Stack2.Navigator>
);

const Tab = createBottomTabNavigator();
const MobileTabNavigator = () => {
  return (
    <Tab.Navigator
      tabBarOptions={{
        activeTintColor: "#004e79",
        inactiveTintColor: "#d6d4db",
      }}
    >
      <Tab.Screen
        name="OrganisationUsersNavigator"
        component={OrganisationUsersNavigator}
        options={{
          tabBarButton: (props) => {
            return (
              <TabButton
                icon={() => (
                  <PeopleOutline width={24} height={24} stroke="#004E7A" />
                )}
                label="Organisation Users"
                {...props}
              />
            );
          },
        }}
      />
      <Tab.Screen
        name="PendingInvitationsNavigator"
        component={PendingInvitationsNavigator}
        options={{
          tabBarButton: (props) => {
            return (
              <TabButton
                icon={() => (
                  <PersonAddOutline width={20} height={20} stroke="#004E7A" />
                )}
                label="Pending Invitations"
                {...props}
              />
            );
          },
        }}
      />
    </Tab.Navigator>
  );
};

const Stack = createStackNavigator();
const WebNavigator = ({ params }) => {
  const web = useAtom(isWebAtom);
  return (
    <Stack.Navigator>
      <Stack.Screen
        name="UsersScreen"
        component={OrganisationUsersScreen}
        initialParams={params}
        options={options(
          () => (
            <BreadCrumb parts={["currentOrganisation.name", "Users"]} />
          ),
          (props) => (
            <View style={{ flexDirection: "row" }}>
              <HeaderButton
                icon={
                  <PersonAddOutline
                    width={20}
                    height={20}
                    stroke="#004E7A"
                    style={{ marginRight: 10, marginTop: 3, marginBottom: 3 }}
                  />
                }
                title="Pending Invitations"
                onPress={() => {
                  props.navigation.navigate("PendingInvitationsScreen");
                }}
              />
            </View>
          )
        )}
      />
      <Stack.Screen
        name="PendingInvitationsScreen"
        component={PendingInvitationsScreen}
        initialParams={params}
        options={optionsBack(
          web,
          () => (
            <BreadCrumb
              parts={[
                "currentOrganisation.name",
                "Users",
                "Pending Invitations",
              ]}
            />
          ),
          (props) => (
            <View style={{ flexDirection: "row" }}>
              <HeaderButton
                icon="add-circle-outline"
                title="Invite User"
                onPress={() => setShowModal(true)}
              />
            </View>
          )
        )}
      />
    </Stack.Navigator>
  );
};

export default ({ route: { params } }) => {
  const web = useAtom(isWebAtom);
  return web ? WebNavigator({ params }) : MobileTabNavigator();
  // return WebNavigator({ params });
};

import React from "react";
import { Ionicons } from "@expo/vector-icons";
import Box from "./Box";
import Touch from "./Touch";
import { Dimensions } from "react-native";

const SortButton = ({
  activeMode = "asc",
  activeColor = "#004e7a",
  inactiveColor = "#aeb1b5",
  onPress,
}) => {
  // checking if screen size is less than 390 (iphone 12) then sort button size is reduced to 25
  const dims = Dimensions.get("screen");

  return (
    <Touch flexDirection="row" alignItems="center" onPress={onPress}>
      <Ionicons
        name="arrow-up"
        size={dims.width < 390 ? 25 : 35}
        color={activeMode === "desc" ? activeColor : inactiveColor}
      />
      <Box marginLeft={4} />
      <Ionicons
        name="arrow-down"
        size={dims.width < 390 ? 25 : 35}
        color={activeMode === "asc" ? activeColor : inactiveColor}
      />
    </Touch>
  );
};

export default SortButton;

import React, { useState } from 'react';
import { View, TouchableOpacity, Platform } from 'react-native';
import * as SecureStore from 'expo-secure-store';
import { Ionicons } from '@expo/vector-icons';
import { Text, Box, Touch, TextInput, ErrorMessage, AuthScreen, CheckBox } from '../../components';
import { Field, FieldLabel, Button, Footer, BackButton } from './common';
import { signIn, initializeAppScreen, testId } from '../../utils';
import { useGlobal, useForm, useLocalStorage } from '../../hooks';

export default ({ route, navigation }) => {
  const { email } = route.params || {};
  const [, updateData] = useGlobal();
  const [rememberDevice, setRememberDevice] = useLocalStorage('rememberDevice', false);
  const [secure, setSecure] = useState(true);
  const { control, isSubmitting, onSubmit, error, errors } = useForm({
    initial: {
      email: email,
    },
    schema: (yup) => ({
      email: yup.string().required('Please enter your email').email('This is not a valid email'),
      password: yup.string().required('No password provided'),
    }),
    onSubmit: async ({ email, password }) => {
      const confirmed = await signIn({
        email: email.toLowerCase(),
        password: password,
      });
      const changeScreen = ({ screen, params }) => navigation.replace(screen, params);
      await initializeAppScreen({ email: email.toLowerCase(), updateData, changeScreen, confirmed });
      if (Platform.OS !== 'web') {
        await SecureStore.setItemAsync('email', email);
        await SecureStore.setItemAsync('password', password);
      }
    },
  });

  return (
    <AuthScreen>
      <Text color="white" fontSize={20} marginBottom={25}>
        Access your account
      </Text>
      <Field>
        <FieldLabel>EMAIL</FieldLabel>
        <TextInput name="email" type="email-address" errors={errors} control={control} onSubmitEditing={onSubmit}/>
      </Field>
      <Field>
        <FieldLabel>PASSWORD</FieldLabel>
        <TextInput
          name="password"
          type="password"
          secure={secure}
          autoCorrect={false}
          spellCheck={false}
          errors={errors}
          control={control}
          onSubmitEditing={onSubmit}
          rightComp={
            <TouchableOpacity onPress={() => setSecure(!secure)}>
              <Ionicons name="eye" size={24} color="white" />
            </TouchableOpacity>
          }
        />
      </Field>
      <ErrorMessage err={error} />
      {Platform.OS !== 'web' ? (
        <View style={{ width: 300, flexDirection: 'row', alignItems: 'center', marginTop: 25, marginBottom: 10 }}>
          <Text flex={1} fontSize={15} textAlign='left' color='#719CB6'>Remember this device</Text>
          <CheckBox color="white" borderColor="white" checked={rememberDevice} onPress={() => setRememberDevice(!rememberDevice)} />
        </View>
      ) : null}
      <Button marginTop={40} title="Log in" loading={isSubmitting} onPress={onSubmit} {...testId('buttonSignIn')} />
      <Box alignItems="center" justifyContent="center">
        <Text color="white" fontSize={14} marginBottom={5}>
          Forgot Password?
        </Text>
        <Touch onPress={() => navigation.navigate('ForgotPasswordScreen')}>
          <Text color="white" fontSize={14} fontWeight="600">
            Reset
          </Text>
        </Touch>
      </Box>
      <Footer style={{ marginTop: 40 }}>
        <BackButton onPress={() => (navigation.canGoBack() ? navigation.goBack() : navigation.replace('SelectScreen'))} />
      </Footer>
    </AuthScreen>
  );
};

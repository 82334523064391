import React, { useState } from 'react';
import { Platform, View, Image, Text, TouchableOpacity } from 'react-native';
import * as Linking from 'expo-linking';
import { Ionicons } from '@expo/vector-icons';
import { getMobileOperatingSystem } from '../utils';
import Logo from '../assets/icon.png';

const AppBanner = () => {
  const [show, setShow] = useState(true);
  const getURL = () => {
    const osName = getMobileOperatingSystem();
    let url = '';
    switch (osName) {
      case 'ios': {
        return 'https://apps.apple.com/gb/app/iowna/id1504627465';
      }
      case 'android': {
        return 'https://play.google.com/store/apps/details?id=com.iowna.app';
      }
      default: {
        return url;
      }
    }
  };
  if (Platform.OS !== 'web') {
    return null;
  }
  return (
    show &&
    getURL() && (
      <View
        style={{ height: 60, alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between', zIndex: 99999, backgroundColor: '#717171' }}
      >
        <View style={{ alignItems: 'center', flexDirection: 'row', marginLeft: 10 }}>
          <TouchableOpacity onPress={() => setShow(false)}>
            <Ionicons name="close" size={32} color="#ffffff" />
          </TouchableOpacity>
          <Image style={{ marginLeft: 10, height: 40, width: 40, borderRadius: 8 }} source={Logo} />
          <View style={{ marginLeft: 10 }}>
            <Text style={{ fontWeight: 500, color: '#ffffff' }}>iOWNA</Text>
            <Text style={{ fontWeight: 500, fontSize: 12, color: '#ffffff' }}>Open in the iOWNA app</Text>
          </View>
        </View>
        <View style={{ flexDirection: 'row', alignItems: 'center', marginLeft: 10, marginRight: 20 }}>
          <TouchableOpacity
            onPress={() => {
              let url = getURL();
              if (url) {
                Linking.openURL(getURL());
              }
            }}
            accessibilityLabel="Download iOWNA App"
          >
            <Text style={{ fontWeight: 700, color: '#ffffff', marginRight: 10 }}>OPEN</Text>
          </TouchableOpacity>
        </View>
      </View>
    )
  );
};

export default AppBanner;

import React, { useEffect, useState } from "react";
import { View, Text as RNText, TouchableOpacity, FlatList } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { get } from "lodash";
import { useInfiniteQuery, useMutation, useQueryClient } from "react-query";
import { getOrganisationBundles } from "../../../graphql";
import New from "../../../assets/icons/new.svg";

import {
  handleError,
  IOWNAError,
  testId,
  transformContentTag,
} from "../../../utils";
import {
  setPdfId,
  confirm,
  useAtom,
  isWebAtom,
  currentOrganisationAtom,
  setBundle,
  bundleAtom,
} from "../../../atoms";
import {
  Accordion,
  AccordionButton,
  MainScreen,
  PaginatedList,
  Tag,
  Text,
} from "../../../components";
import { UserName } from "../../../containers";
import { createOrganisationBundle } from "../../../graphql";

const Button = ({ text, onPress }) => {
  const web = useAtom(isWebAtom);
  return (
    <TouchableOpacity
      {...testId(`${text}`)}
      style={{
        backgroundColor: "white",
        borderColor: "rgb(0, 78, 122)",
        borderWidth: 1,
        padding: web ? 8 : 5,
        borderRadius: web ? 3 : 0,
      }}
      onPress={onPress}
    >
      <RNText style={{ color: "rgb(0, 78, 122)" }}>{text}</RNText>
    </TouchableOpacity>
  );
};

export default function BundleAdviceListScreen({ route, navigation }) {
  const { canSetAdvice, bundleId, displayAs, selectionOriginatingScreen } =
    route.params;

  const currentOrganisation = useAtom(currentOrganisationAtom);

  const apiFn = (props) =>
    getOrganisationBundles({
      id: currentOrganisation.id,
      ...props,
    });

  const pathFn = (result) => {
    let identifiedBundle = result.data.getOrganisation.bundles.items.find(
      (i) => i.id === bundleId
    );

    return identifiedBundle;
  };

  const {
    data: pageData,
    fetchNextPage: loadMore,
    hasNextPage,
    isLoading,
    isFetchingNextPage,
  } = useInfiniteQuery(
    [currentOrganisation.id, "bundles", bundleId],
    ({ pageParam }) => apiFn(pageParam).then(pathFn),
    {
      getNextPageParam: (lastPage) =>
        lastPage.nextToken ? { nextToken: lastPage.nextToken } : undefined,
    }
  );

  const loading = isLoading || isFetchingNextPage;
  const bundle = pageData ? pageData.pages.flatMap((item) => item) : [];

  let selectedBundle = bundle[0];

  const [currentOpenItem, setCurrentOpenItem] = useState();

  const queryClient = useQueryClient();

  const onRemovePress = (bundle, advice) => {
    confirm(
      "Delete Content",
      `Are you sure you want to delete "${advice.displayName}" from bundle?`,
      handleError(async (bundle, advice) => {
        try {
          await createOrganisationBundle({
            input: {
              id: bundle.id,
              name: bundle.name,
              organisationId: bundle.organisationId,
              adviceIds: bundle.adviceIds.filter(
                (adviceId) => adviceId !== advice.id
              ),
            },
          });
     
          queryClient.invalidateQueries([
            bundle.organisationId,
            "bundles",
            bundle.id,
          ]);
        } catch (err) {
          const message = get(err, "errors.0.message", "");
          if (
            message.includes("Not Authorized") &&
            message.includes("createOrganisationBundle")
          ) {
            throw new IOWNAError(
              `You can only edit a bundle uploaded by you. Please contact an administrator if you would like to delete any content to "${bundle.name}".`
            );
          } else throw err;
        }
      })
    )(bundle, advice);
  };

  const SendBundle = () => {
    return (
      <Button
        text={"Select"}
        onPress={() => {
          const items = selectedBundle.advices
            .filter((a) => a.state !== "archived")
            .map((advice) => ({
              id: advice.id,
              displayName: advice.displayName,
            }));
          navigation.navigate(selectionOriginatingScreen, {
            contents: [
              ...items,
              ...route.params.selected.filter(
                (i) => !items.some((bi) => bi.id === i.id)
              ),
            ],
          });
        }}
      />
    );
  };

  return (
    <MainScreen backgroundColor="white">
      {canSetAdvice && !loading ? (
        <View
          style={{
            flexDirection: "row",
            alignItems: "flex-start",
            paddingVertical: 10,
            paddingLeft: 20,
            backgroundColor: "white",
            borderBottomColor: "rgb(198, 198, 200)",
            borderBottomWidth: 1,
          }}
        >
          {canSetAdvice ? <SendBundle /> : null}
        </View>
      ) : null}
      {!loading ? (
        <PaginatedList
          name="bundles"
          displayAs={displayAs}
          displayOtherList={true}
          otherList={(selectedBundle.advices || []).filter(
            (advice) => advice.state !== "archived"
          )}
          emptyTitle={"Nothing is here yet..."}
          emptyDescription={
            <RNText>Please add content via the 'Manage Content' page</RNText>
          }
          onRowPress={handleError((item) => {
            setPdfId(item.id);
            navigation.navigate("PdfScreen", {
              id: item.id,
            });
          })}
          columnConfig={[
            [
              { width: "40%", text: "Title" },
              { width: "30%", text: "Tags" },
              { width: "20%", text: "Uploaded by" },
              {
                width: "10%",
                text: "Actions",
                style: { justifyContent: "center" },
              },
            ],
          ]}
          rowConfig={[
            { width: "40%", text: (item) => item.displayName },
            {
              width: "30%",
              text: (item) =>
                (item.tags || []).map((tag) => (
                  <Tag text={transformContentTag(tag)} />
                )),
            },
            {
              width: "20%",
              render: (item) =>
                item.createdBy ? <UserName id={item.createdBy} /> : null,
            },
            {
              width: "10%",
              render: (item) => {
                return (
                  <View style={{ flexDirection: "row" }}>
                    <TouchableOpacity
                      onPress={() =>
                        onRemovePress(selectedBundle, item, updateData)
                      }
                    >
                      <Ionicons name="trash" size={24} color="#5386A5" />
                    </TouchableOpacity>
                  </View>
                );
              },
              style: { justifyContent: "center" },
            },
          ]}
          renderItem={({ item }) => (
            <Accordion
              showArrow={!canSetAdvice}
              open={currentOpenItem?.id === item.id}
              onPress={() => {
                if (canSetAdvice) {
                  setPdfId(item.id);
                  navigation.navigate("PdfScreen", {
                    id: item.id,
                    canSetAdvice: false,
                    canSendAdvice: false,
                  });
                } else {
                  setCurrentOpenItem((currentOpenItem) =>
                    currentOpenItem?.id !== item.id ? item : null
                  );
                }
              }}
              title={
                <View
                  {...testId(`${item.displayName}`)}
                  style={{
                    flex: 1,
                    flexDirection: "row",
                    padding: 20,
                    backgroundColor: "white",
                  }}
                >
                  <View style={{ flex: 1 }}>
                    <Text style={{ fontSize: 16, color: "#000000" }}>
                      {item.displayName}
                    </Text>
                  </View>
                </View>
              }
            >
              <AccordionButton
                items={[
                  {
                    title: "View",
                    icon: <Ionicons name="eye" size={18} color="#004e7a" />,
                    onPress: () => {
                      setPdfId(item.id);
                      navigation.navigate("PdfScreen", {
                        id: item.id,
                        canSetAdvice: false,
                        canSendAdvice: false,
                      });
                    },
                  },
                  {
                    title: "Remove",
                    icon: (
                      <Ionicons name="trash" size={18} color="#004e7a" />
                    ),
                    onPress: () => {
                      onRemovePress(selectedBundle, item);
                      setCurrentOpenItem(null);
                      console.log("here is the query key for onRemove", [
                        currentOrganisation.id,
                        "bundles",
                        bundleId,
                      ]);
                    },
                  },
                ]}
              />
            </Accordion>
          )}
        />
      ) : null}
    </MainScreen>
  );
}

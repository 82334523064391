import React, { useState } from "react";
import { View, Text, TouchableOpacity } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import {
  MainScreen,
  Result,
  CenteredSpinner,
  TextInput,
  ErrorMessage,
} from "../../../components";
import { useForm } from "../../../hooks";
import { createOrganisationBundle } from "../../../graphql";
import { testId } from "../../../utils";

import { currentOrganisationAtom, isWebAtom, useAtom } from "../../../atoms";

const SaveButton = ({ text, loading, onPress }) => {
  if (loading) {
    return (
      <View style={{ flex: 1, alignItems: "center", justifyContent: "center" }}>
        <CenteredSpinner />
      </View>
    );
  }
  return (
    <View style={{ alignItems: "center" }}>
      <TouchableOpacity
        {...testId(`${text}`)}
        style={{
          backgroundColor: "white",
          borderColor: "rgb(0, 78, 122)",
          borderWidth: 2,
          borderRadius: 35,
          paddingHorizontal: 16,
          paddingVertical: 8,
        }}
        onPress={onPress}
      >
        <Text style={{ color: "rgb(0, 78, 122)", fontWeight: "600" }}>
          {text}
        </Text>
      </TouchableOpacity>
    </View>
  );
};

export function ContentSentScreen({ navigation, route }) {
  const { contentList, sendDateTime, name } = route.params;
  const web = useAtom(isWebAtom);
  const [isBundleSaved, setIsBundleSaved] = useState(false);
  const [okButton, setOkButton] = useState(true);
  const [bundleCopyNeeded, setBundleCopyNeeded] = useState(() =>
    contentList.length > 1 ? true : false
  );
  const [date, setDate] = useState(() => {
    if (sendDateTime) {
      return `${sendDateTime}`;
    }
  });

  const [heading, setHeading] = useState("Content Sent");
  const [description, setDescription] = useState(() => {
    let bundleName =
    contentList.length === 1 ? contentList[0].displayName : "Your Content";
  if (sendDateTime) {
    return `${bundleName} has been scheduled to send on`;
  } else {
    return `${bundleName} has been sent`;
  }
  });

  const contactName = name != null ? `to ${name}` : ""; 

  const contentIds = contentList.map((item) => item.id);

  const { control, onSubmit, isSubmitting, errors, error, watch } = useForm({
    initial: {
      bundleName: "",
    },
    schema: (yup) => ({
      bundleName: yup.string().required("Please enter the bundle name"),
    }),
    onSubmit: async ({ bundleName }) => {
      setOkButton(false);
      setBundleCopyNeeded(false);
      console.log("gets here");
      const response = await createOrganisationBundle({
        input: {
          name: bundleName,
          organisationId: currentOrganisationAtom.getValue().id,
          adviceIds: contentIds,
        },
      });

      if (response.data.createOrganisationBundle.name) {
        setHeading("Bundle Saved");
        setDescription(
          `Your bundle has been saved as "${response.data.createOrganisationBundle.name}"`
        );
        setIsBundleSaved(true);
        setOkButton(true);
      }
    },
  });
  return (
    <MainScreen
      backgroundColor="white"
      style={{ alignItems: "center", flex: 1 }}
    >
      <View style={{ maxWidth: web ? 600 : 320, flex: 3 }}>
        {isSubmitting ? (
          <CenteredSpinner />
        ) : (
          <Result
            icon={
              <Ionicons
                name="checkmark-circle"
                size={80}
                color="green"
                // style={{ marginBottom: 20, marginTop: 100 }}
              />
            }
            title={heading}
            description={description}
            date={date}
            to={contactName}
            showOkBtn={okButton}
            onPressOk={() => navigation.goBack()}
          />
        )}
      </View>

      {!isBundleSaved && bundleCopyNeeded ? (
        <View
          style={{
            alignItems: "center",
            justifyContent: "center",
            maxWidth: web ? 600 : 320,
            flex: 1,
          }}
        >
          <Text
            style={{
              marginVertical: 30,
              fontSize: web ? 18 : 14,
              marginHorizontal: 20,
              textAlign: "center",
            }}
          >
            You can save this selection of content as a bundle to send again by
            giving it a name
          </Text>
          {/* </View> */}
          <View style={{ flex: 1 }}>
            <TextInput
              name="bundleName"
              control={control}
              errors={errors}
              width="100%"
            />
          </View>
          <View
            style={{
              marginTop: 10,
              alignItems: "center",
              justifyContent: "center",
              flex: 1,
            }}
          >
            <ErrorMessage err={error} />
          </View>
          <View
            style={{
              paddingVertical: 30,
              flex: 1,
            }}
          >
            <SaveButton
              text="Save Bundle"
              onPress={onSubmit}
              loading={isSubmitting}
            />
          </View>
          <View style={{ flex: 1 }}>
            <Text style={{ fontSize: web ? 18 : 14, textAlign: "center" }}>
              Other members of your organisation will also be able to select
              this bundle
            </Text>
            <View style={{ flex: 1, padding: web ? 30 : null }}></View>
          </View>
        </View>
      ) : null}
    </MainScreen>
  );
}

import React, { useState, useMemo, useEffect } from "react";
import { StyleSheet, TextInput, View } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { debounce } from "lodash";

export default function ({
  setSearchPhrase,
  onSubmit,
}: {
  setSearchPhrase: (text: string) => void;
  onSubmit?: () => void;
}) {
  const [clicked, setClicked] = useState(false);

  const setDebouncedSearchPhrase = useMemo(
    () => debounce(setSearchPhrase, 200),
    [setSearchPhrase]
  );

  useEffect(() => {
    return () => {
      setDebouncedSearchPhrase.cancel();
    };
  }, []);

  const [searchPhrase, _setSearchPhrase] = useState("");

  useEffect(() => setDebouncedSearchPhrase(searchPhrase), [searchPhrase]);

  return (
    <View style={{ height: 40 }}>
      <View style={styles.container}>
        <View
          style={
            !clicked ? styles.searchBar__unclicked : styles.searchBar__clicked
          }
        >
          <Ionicons
            name="search"
            size={20}
            color="#848488"
            style={{ marginLeft: 1 }}
          />

          <TextInput
            style={styles.input}
            placeholder="Search"
            value={searchPhrase}
            onChangeText={_setSearchPhrase}
            onFocus={() => setClicked(true)}
            onBlur={() => setClicked(false)}
            onSubmitEditing={() => onSubmit?.()}
          />
          {!!searchPhrase && (
            <Ionicons
              name="close-outline"
              size={16}
              color="#848488"
              style={{ padding: 1 }}
              onPress={() => _setSearchPhrase("")}
            />
          )}
        </View>
      </View>
    </View>
  );
}

const styles = StyleSheet.create({
  container: {
    justifyContent: "flex-start",
    alignItems: "center",
    flexDirection: "row",
    flex: 1,
  },
  searchBar__unclicked: {
    flex: 1,
    padding: 10,
    flexDirection: "row",
    backgroundColor: "#f2f2f2",
    borderRadius: 10,
    alignItems: "center",
  },
  searchBar__clicked: {
    flex: 1,
    padding: 10,
    flexDirection: "row",
    backgroundColor: "#f2f2f2",
    borderRadius: 10,
    alignItems: "center",
    justifyContent: "space-evenly",
  },
  input: {
    flex: 1,
    fontSize: 16,
    marginLeft: 10,
  },
});

import React from 'react';
import { TouchableOpacity } from 'react-native';
import { Ionicons, MaterialCommunityIcons } from '@expo/vector-icons';
import { useAtom, isWebAtom } from '../atoms';

export default function CheckBox({ checked, color = 'black', borderColor = '#626262', onPress }) {
  const web = useAtom(isWebAtom);
  const size = web ? 24 : 33;
  return (
    <TouchableOpacity
      style={{
        height: size,
        width: size,
        borderColor: borderColor,
        borderWidth: 1,
        alignItems: 'center',
        justifyContent: 'center',
      }}
      onPress={onPress}
    >
      {checked && <Ionicons testID="checkbox-mark" name="checkmark" size={web ? 36 : 33} color={color} />}
    </TouchableOpacity>
  );
}

export const RoundedCheckBox = ({
  checked,
  checkedIcon,
  uncheckedIcon,
  checkedIconProps = {},
  uncheckedIconProps = {},
  size = 28,
  checkedColor = "#004E7A",
  uncheckedColor = "#AEB1B5",
  onPress = () => { }
}) => {
  const uncheckedChildren = uncheckedIcon || <MaterialCommunityIcons name="checkbox-blank-circle-outline" size={size} color={uncheckedColor} {...checkedIconProps} />
  const checkedChildren = checkedIcon || <MaterialCommunityIcons name="check-circle" size={size} color={checkedColor} {...uncheckedIconProps} />

  return (
    <TouchableOpacity activeOpacity={1} onPress={onPress} style={{ height: size, width: size }}>
      {checked ? checkedChildren : uncheckedChildren}
    </TouchableOpacity>
  )
} 
import React from 'react';
import { View } from 'react-native';
import { Ionicons } from '@expo/vector-icons';
import { HeaderButton } from '../components';
import NotificationCount from './NotificationCount';

const NotificationIcon = ({ navigation }) => {
  return (
    <HeaderButton
      hasBorder={false}
      customIcon={
        <View>
          <NotificationCount size="small" position="absolute" />
          <Ionicons name="notifications-outline" size={24} color="#004E7A" style={{ marginRight: 10 }} />
        </View>
      }
      onPress={() => {
        navigation.navigate('NotificationsNavigator', { showBackButton: true });
      }}
    />
  );
};

export default NotificationIcon;

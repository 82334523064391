import React from "react";
import styled from "@emotion/native";
import {
  Platform,
  View,
  Text,
  TouchableWithoutFeedback,
  Dimensions,
} from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { MenuProvider } from "react-native-popup-menu";
import { KeyboardAwareScrollView } from "react-native-keyboard-aware-scroll-view";
import Spinner from "./Spinner";

const Modal =
  Platform.OS === "web"
    ? require("./ReactNativeModal").default
    : require("react-native-modal").default;

const web = Platform.OS === "web";

export const FieldLabel = styled.Text`
  font-size: 16;
  font-weight: 500;
  color: ${(props) => props.theme.text};
  margin-top: 30;
  margin-bottom: 10;
`;

export default function CustomModal({
  title,
  isVisible,
  onClose,
  onPressBack,
  hideCrossBtn,
  onBackdropPress,
  style = {},
  contentStyle = {},
  isList,
  children,
}) {
  if (React.Children.count(children) !== 1) {
    throw new Error("You can pass only 1 child");
  }
  const Container = isList ? View : KeyboardAwareScrollView;
  return (
    <Modal
      isVisible={isVisible}
      animationIn="fadeInUp"
      animationOut="fadeOutDown"
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 10,
        padding: 20,
      }}
      onBackdropPress={() => {
        typeof onBackdropPress === "function" ? onBackdropPress() : onClose();
      }}
    >
      <MenuProvider skipInstanceCheck>
        <Container
          extraHeight={200}
          contentContainerStyle={{
            flexGrow: 1,
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <View
            style={{
              backgroundColor: "white",
              borderRadius: 10,
              width: Math.round(Dimensions.get("window").width) - 40,

              ...style,
            }}
          >
            {title ? (
              <View
                style={{
                  flexDirection: "row",
                  paddingTop: 15,
                  paddingBottom: 15,
                  marginBottom: 10,
                  backgroundColor: "#f2f2f2",
                  borderBottomColor: "#f2f2f2",
                  borderBottomWidth: 1,
                  borderTopLeftRadius: 10,
                  borderTopRightRadius: 10,
                }}
              >
                {onPressBack ? (
                  <View
                    style={{
                      marginLeft: 10,
                      paddingLeft: 10,
                      cursor: "pointer",
                    }}
                  >
                    <TouchableWithoutFeedback onPress={onPressBack}>
                      <Ionicons
                        name="arrow-back"
                        size={24}
                        color="#8599b3"
                      />
                    </TouchableWithoutFeedback>
                  </View>
                ) : null}
                <View
                  style={{
                    flex: 1,
                    alignItems: "center",
                    marginLeft: 10,
                    paddingLeft: 10,
                    cursor: "default",
                  }}
                >
                  <Text style={{ fontSize: 20, color: "rgb(12, 82, 125)" }}>
                    {title}
                  </Text>
                </View>
                {!hideCrossBtn ? (
                  <View
                    style={{
                      marginRight: 10,
                      paddingRight: 10,
                      cursor: "pointer",
                    }}
                  >
                    <TouchableWithoutFeedback onPress={() => onClose()}>
                      <Ionicons name="close" size={24} color="#8599b3" />
                    </TouchableWithoutFeedback>
                  </View>
                ) : null}
              </View>
            ) : null}
            <View
              style={{
                padding: 20,
                ...contentStyle,
              }}
            >
              {React.Children.map(children, (child) => {
                return React.cloneElement(child, { onClose });
              })}
            </View>
          </View>
        </Container>
      </MenuProvider>
    </Modal>
  );
}

export const SpinnerModal = ({ isVisible, onClose }) => {
  return (
    <Modal
      isVisible={isVisible}
      style={{
        marginLeft: "auto",
        marginRight: "auto",
        borderRadius: 10,
        padding: 20,
      }}
      onBackdropPress={() => onClose()}
    >
      <Spinner size="large" />
    </Modal>
  );
};

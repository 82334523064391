import React, { Fragment } from "react";
import { View, Text, TextInput, Platform } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import { Controller } from "react-hook-form";
import { useTheme } from "@emotion/react";
import {
  Menu,
  MenuOptions,
  MenuOption,
  MenuTrigger,
  renderers,
} from "react-native-popup-menu";
import ErrorMessage from "./ErrorMessage";

const onChangeString = (args) => args.nativeEvent.text;

const onChangeNumber = (args) => args.nativeEvent.text.replace(/[^0-9]/g, "");

const Input = ({
  name,
  secure,
  help,
  rightComp,
  renderHelp,
  type,
  placeholder,
  fontSize,
  width,
  color,
  error,
  onChange,
  value,
  multiline,
  readOnly,
  ...props
}) => {
  const theme = useTheme();
  const border = multiline
    ? {
        borderWidth: 1,
        borderColor: error ? theme.error : color || theme.textInputBorderBottom,
      }
    : {
        borderBottomWidth: readOnly ? 0 : 1,
        borderBottomColor: error
          ? theme.error
          : color || theme.textInputBorderBottom,
      };
  const input = {
    flex: 1,
    width: width,
    height: multiline ? 170 : 40,
    fontSize: fontSize,
    color: readOnly ? "white" : color || theme.textInputText,
    borderBottomWidth: 0,
    padding: 3,
    paddingRight: help ? 10 : 3,
  };
  if (Platform.OS === "web") {
    input.outline = "none";
  }
  const secureProps = {};
  if (Platform.OS === "ios" && secure) {
    input.fontFamily = "Text-Security-Disc";
    secureProps.secureTextEntry = false;
  }
  return (
    <View
      style={{
        flexDirection: "row",
        height: multiline ? 170 : 40,
        width: width,
        ...border,
      }}
    >
      <TextInput
        style={input}
        autoCapitalize="none"
        testID={"TextInput_" + name}
        accessibilityLabel={Platform.select({ android: "TextInput_" + name })}
        autoCorrect={false}
        autoCompleteType="off"
        multiline={multiline}
        secureTextEntry={secure}
        placeholder={placeholder}
        keyboardType={type || "default"}
        returnKeyType="done"
        placeholderTextColor={color || theme.textInputPlaceholder}
        onChange={onChange}
        value={value}
        editable={!readOnly}
        textContentType={secure ? "oneTimeCode" : "none"}
        importantForAutofill="no"
        autocomplete="off"
        {...secureProps}
        {...props}
      />
      {rightComp ? rightComp : null}
      {help && (
        <Menu
          renderer={renderers.Popover}
          rendererProps={{
            preferredPlacement: "top",
            anchorStyle: { marginTop: -50 },
          }}
        >
          <MenuTrigger>
            <Ionicons name="help-circle" size={24} color="white" />
          </MenuTrigger>
          <MenuOptions>
            <MenuOption>
              {renderHelp || (
                <Fragment>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Text>•</Text>
                    <Text style={{ fontSize: 12, padding: 3 }}>
                      Password must be atleast 8 characters
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Text>•</Text>
                    <Text style={{ fontSize: 12, padding: 3 }}>
                      Password must have a Lowercase character
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Text>•</Text>
                    <Text style={{ fontSize: 12, padding: 3 }}>
                      Password must have a Uppercase character
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Text>•</Text>
                    <Text style={{ fontSize: 12, padding: 3 }}>
                      Password must have a Special character
                    </Text>
                  </View>
                  <View style={{ flexDirection: "row", alignItems: "center" }}>
                    <Text> </Text>
                    <Text> </Text>
                    <Text style={{ fontSize: 12, padding: 3 }}>
                      in ?=.[!@#\$%\^&]
                    </Text>
                  </View>
                </Fragment>
              )}
            </MenuOption>
          </MenuOptions>
        </Menu>
      )}
    </View>
  );
};

export default ({
  name,
  secure,
  help,
  type,
  placeholder,
  width,
  fontSize = 18,
  color,
  control,
  errors,
  rules,
  onSubmitEditing = undefined,
  multiline = false,
  readOnly = false,
  hasNumbersOnly = false,
  ...props
}) => {
  return (
    <React.Fragment>
      <Controller
        render={({ field: {onChange, value} }) => (
          <Input
            secure={secure}
            help={help}
            spellCheck={false}
            autoCorrect={false}
            type={type}
            placeholder={placeholder}
            fontSize={fontSize}
            width={width}
            color={color}
            error={errors[name]}
            multiline={multiline}
            readOnly={readOnly}
            value={value}
            onChange={(val) => {
              onChange((hasNumbersOnly ? onChangeNumber : onChangeString)(val));
            }}
            onSubmitEditing={onSubmitEditing}
            {...props}
          />
        )}
        control={control}
        name={name}
        rules={rules}
      />
      <ErrorMessage name={name} errors={errors} />
    </React.Fragment>
  );
};

import React from "react";
import { View, Text, Platform } from "react-native";
import { Ionicons } from "@expo/vector-icons";
import Touch from "./components/Touch";
import IconLogo from "./assets/images/logo.svg";
import { useAtom, isWebAtom } from "./atoms";
// import { isConditionalExpression } from 'typescript';

export const displayTitle = (title, props) => {
  if (typeof title === "string") {
    return (
      <View style={{ marginLeft: 13, marginRight: 10 }}>
        <Text
          style={{ fontSize: 20, fontWeight: "500", color: "rgb(0, 78, 122)" }}
        >
          {title}
        </Text>
      </View>
    );
  } else if (typeof title === "function") {
    const result = title(props);
    if (typeof result === "string") {
      return (
        <View style={{ marginLeft: 13, marginRight: 10 }}>
          <Text
            style={{
              fontSize: 20,
              fontWeight: "500",
              color: "rgb(0, 78, 122)",
            }}
          >
            {result}
          </Text>
        </View>
      );
    } else {
      return result;
    }
  } else {
    return null;
  }
};

const HeaderLeft = ({ navigation }) => {
  const web = useAtom(isWebAtom);
  if (web) {
    return null;
  }
  return (
    <Ionicons
      name="menu-outline"
      size={32}
      style={{ marginLeft: 20 }}
      onPress={() => navigation.toggleDrawer()}
    />
  );
};

export const HeaderTitle = ({ title, ...props }) => {
  const web = useAtom(isWebAtom);
  if (web) {
    return displayTitle(title, props);
  }
  const marginLeft =
    Platform.OS === "web" || Platform.OS === "android" ? 50 : 0;
  const newTitle = typeof title === "function" ? title(props) : title;
  if (typeof newTitle === "string") {
    return (
      <View
        style={{
          flexShrink: 1,
          flexDirection: "row",
          alignItems: "center",
          justifyContent: "center",
          marginLeft: marginLeft + 5,
          marginRight: 30,
        }}
      >
        <Text
          style={{ fontSize: 20, fontWeight: "500", color: "rgb(0, 78, 122)" }}
        >
          {newTitle}
        </Text>
      </View>
    );
  }
  if (typeof newTitle === "object") {
    return (
      <View
        style={{
          flexShrink: 1,
          alignItems: "center",
          justifyContent: "center",
          flexDirection: "row",
          marginLeft: marginLeft + 5,
          marginRight: 30,
        }}
      >
        {newTitle}
      </View>
    );
  }
  return (
    <View
      style={{
        alignItems: "center",
        justifyContent: "center",
        marginLeft: marginLeft,
      }}
    >
      <IconLogo width={120} height={120} />
    </View>
  );
};

export const options = (title, headerRight) => (props) => ({
  headerShown: true,
  headerTitleContainerStyle: {
    left: 10,
  },
  headerLeft: () => <HeaderLeft navigation={props.navigation} />,
  headerTitle: () => <HeaderTitle title={title} {...props} />,
  headerRight: () => displayTitle(headerRight, props),
});

export const optionsBack =
  (web, title, headerRight, backScreen) => (props) => ({
    headerShown: true,
    gestureEnabled: false,
    headerTitleContainerStyle: {
      left: web ? 40 : 0,
    },
    headerLeft: () => (
      <Touch
        marginLeft={10}
        onPress={() => {
          if (backScreen) {
            props.navigation.navigate(backScreen);
          } else {
            props.navigation.goBack();
          }
        }}
      >
        <Ionicons name="arrow-back-outline" size={32} color="#004e79" />
      </Touch>
    ),
    headerTitle: () => <HeaderTitle title={title} {...props} />,
    headerRight: () => displayTitle(headerRight, props),
  });

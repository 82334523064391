import React, { useState, useEffect } from "react";
import {
  View,
  Text,
  Platform,
  TouchableOpacity,
  StyleSheet,
} from "react-native";
import { get } from "lodash";
import { format } from "date-fns";
import Constants from "expo-constants";
import { options, optionsBack } from "../../../headerUtils";
import { HeaderButton, MainScreen, Switch } from "../../../components";
import { BreadCrumb } from "../../../containers";
import { useLocalStorage } from "../../../hooks";
import featureFlagsConfig from "../../../../featureFlags.json";
import NewButton from "../../../components/NewButton";

import {
  alert,
  setPdfId,
  userByIdAtom,
  useAtom,
  currentUserAtom,
  userPushTokensAtom,
  isWebAtom,
  currentOrganisationAtom,
} from "../../../atoms";
import {
  nameTitle,
  interpolate,
  askPushNotificationPermission,
  registerForPushNotifications,
  unRegisterForPushNotifications,
  checkAllowPushNotification,
  logEvent,
} from "../../../utils";
import { screen, containerWithoutLoader } from "../../../reactUtils";
import { getUserSubmissions } from "../../../graphql";
import { useInfiniteQuery } from "react-query";

import List from "../../../new/components/List";

const transformSubmissionObject = (item) => {
  const answers =
    item.submission != null && item.submission.answers
      ? JSON.parse(item.submission.answers)
      : {};
  const form =
    item.submission != null && item.submission.staticAdvice.form
      ? JSON.parse(item.submission.staticAdvice.form)
      : {};
  const data = [];
  if (item.submission != null && item.submission.updatedAt) {
    form.pages.forEach((page) => {
      if (page.show) {
        const result = interpolate(page.show, {
          ...answers,
          completed: true,
        });
        if (result === "false") {
          return;
        }
      }
      page.questions.forEach((question) => {
        if (question.id) {
          let ans = answers[question.id];
          if (question.type === "radio") {
            ans = question.choices
              .filter((choice) =>
                Array.isArray(ans) ? ans.includes(choice.id) : ans === choice.id
              )
              .map((selectedChoices) => selectedChoices.name);
          }
          data.push([question.shortName || question.title, ans]);
        }
      });
    });
  }
  const results = form?.results || [];

  return {
    ...item,
    title: item.submission != null ? form.title : "",
    answers: item.submission != null ? answers : [],
    tableResults: item.submission != null ? data : [],
    results:
      item.submission != null && item.submission.updatedAt
        ? results.map((result) => ({
            label: result.label,
            value: interpolate(result.value, answers),
          }))
        : [],
  };
};

const Header = ({ title, paddingTop = 0 }) => {
  const web = useAtom(isWebAtom);
  const margin = web ? "20%" : 0;
  return (
    <View
      style={{
        backgroundColor: "#ebebf1",
        paddingRight: 16,
        paddingLeft: 16,
        paddingTop: paddingTop + 4,
        paddingBottom: 4,
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "flex-start",
        borderColor: "rgb(220, 220, 223)",
        borderWidth: 1,
        marginLeft: margin,
        marginRight: margin,
      }}
    >
      <Text
        style={{
          fontSize: web ? 22 : 15,
          fontWeight: "500",
          color: "#424246",
        }}
      >
        {title}
      </Text>
    </View>
  );
};

const Field = ({ fontSize, title, date, value, borderWidth, ...props }) => {
  const web = useAtom(isWebAtom);
  const padding = web ? 20 : 13;

  return (
    <View
      style={{
        flex: 1,
        flexDirection: "row",
        paddingTop: padding,
        paddingBottom: padding - 5,
        backgroundColor: "white",
        alignItems: "center",
        justifyContent: "flex-start",
        paddingHorizontal: 18,
        borderBottomWidth: borderWidth,
        borderBottomColor: "#C6C6C8",
      }}
      {...props}
    >
      <View>
        <Text style={{ fontSize: fontSize || 15, paddingRight: 8 }}>
          {title}
        </Text>
        <Text
          style={{
            fontSize: fontSize || 14,
            paddingRight: 8,
            color: "#aaaaaa",
            opacity: 1,
          }}
        >
          {date}
        </Text>
      </View>
      <View
        style={{ flex: 1, alignItems: "flex-end", justifyContent: "flex-end" }}
      >
        {typeof value === "string" ? (
          <Text style={{ fontSize: fontSize || 16, paddingLeft: 8 }}>
            {value}
          </Text>
        ) : (
          value
        )}
      </View>
    </View>
  );
};

const ProfileScreen = screen(({ navigation: { navigate }, route }) => {
  const web = useAtom(isWebAtom);

  const currentUser = useAtom(currentUserAtom);

  const [rememberDevice, setRememberDevice] = useLocalStorage(
    "rememberDevice",
    false
  );
  const [allowNotifications, setAllowNotifications] = useState(false);
  
  const isDeleteAccountEnabled = featureFlagsConfig.featureFlags.deleteAccount.enabled;

  
  
  const user = currentUser;





  const apiFn = (props) => getUserSubmissions({ id: user.id, ...props });

  const pathFn = (result) => {
    const resultArr = result.data.getUser.submissions;
    return {
      ...resultArr,
      items: resultArr.items.map((e) => ({
        ...e,
        ...transformSubmissionObject({ submission: e }),
      })),
    };
  };

  const {
    data: pageListData,
    fetchNextPage: listLoadMore,
    hasNextPage: listHasMore,
    isFetchingNextPage: isListFetchingNextPage,
    isLoading: isListLoading,
  } = useInfiniteQuery(
    ["me", "activity"],
    ({ pageParam }) => apiFn(pageParam).then(pathFn),
    {
      getNextPageParam: (lastPage) =>
        lastPage.nextToken ? { nextToken: lastPage.nextToken } : undefined,
    }
  );

  const listLoading = isListLoading || isListFetchingNextPage;

  const loading = listLoading;

  const [searchText, setSearchText] = useState("");

  const data = pageListData ? pageListData.pages.flatMap((x) => x.items) : [];

  if (data.length < 50 && !listLoading && listHasMore) {
    listLoadMore();
  }

  const pushTokens = useAtom(userPushTokensAtom);
  useEffect(() => {
    if (Platform.OS !== "web" && Constants.isDevice) {
      checkAllowPushNotification(pushTokens).then(setAllowNotifications);
    }
  }, [pushTokens.length]);
  const title = get(user, "title", "");
  const firstName = get(user, "firstName", "");
  const lastName = get(user, "lastName", "");
  const id = get(user, "id", "");
  const margin = web ? "20%" : 0;
  const paddingTop = web ? 10 : 20;
  const showPrefs = Platform.OS !== "web";

  const userListItem = ({ item, index }) => {
    const result = item.results[0] || {
      label: item.updatedAt ? "Completed" : "Complete it",
      value: "",
    };
    return (
      <Field
        key={item.id + result.label + result.value}
        title={item.title}
        borderWidth={1}
        date={format(
          new Date(item.updatedAt ? item.updatedAt : item.createdAt),
          "dd/MM/yyyy"
        )}
        value={
          <TouchableOpacity
            style={{
              flex: 1,
              alignItems: "flex-end",
              justifyContent: "flex-end",
            }}
            onPress={() => {
              setPdfId(item.staticAdvice.id);
              navigate("PdfScreen", {
                id: item.staticAdvice.id,
                contentId: item.id,
                consumer: true,
              });
            }}
          >
            <Text style={{ fontSize: 14, color: "#426af3" }}>
              {result.label + " " + result.value}
            </Text>
          </TouchableOpacity>
        }
      />
    );
  };

  return (
    <MainScreen backgroundColor="#F2F2F7" style={{ flex: 1 }}>
      <Header title="Profile" paddingTop={paddingTop} />
      <View
        style={{
          backgroundColor: "white",
          alignItems: web ? "none" : "center",
          marginHorizontal: margin,
          paddingHorizontal: 16,
        
        }}
      >
        <Field
          title="Title"
          value={nameTitle(title)}
          borderWidth={1}
          testID="title"
          accessibilityLabel={Platform.select({ android: "title" })}
        />
        <Field
          title="First Name"
          value={firstName}
          borderWidth={1}
          testID="first name"
          accessibilityLabel={Platform.select({ android: "first name" })}
        />
        <Field
          title="Last Name"
          value={lastName}
          borderWidth={1}
          testID="last name"
          accessibilityLabel={Platform.select({ android: "last name" })}
        />
        <Field
          title="iOWNA ID"
          value={id.substring(0, 7)}
          borderWidth={1}
          testID="id"
          accessibilityLabel={Platform.select({ android: "iowna id" })}
        />
      </View>
      {isDeleteAccountEnabled && (  
        <View style={{paddingVertical: 16}}><NewButton
            onPress={() => {
              console.log('delete api to go here')
            }}
            title={"Delete Account"}
            type={"primary"}
          /></View> )}
      {data.length > 0 ? (
        <>
          <Header title="Submissions" paddingTop={paddingTop} />
          <List
            hideSearch={true}
            flatListParams={{
              data: data.filter((i) =>
                i.staticAdvice.displayName.toLowerCase().includes(searchText)
              ),
              renderItem: userListItem,
              onEndReached: listLoadMore,
            }}
            loading={loading}
          />
        </>
      ) : null}
      {showPrefs ? (
        <Header title="Preferences" paddingTop={paddingTop} />
      ) : null}
      <View
        style={{
          backgroundColor: "white",
          alignItems: web ? "none" : "center",
          // marginLeft: margin,
          // marginRight: margin,
          paddingHorizontal: 16,
        }}
      >
        {showPrefs ? (
          <Field
            title="Remember Device"
            value={
              <Switch
                value={rememberDevice}
                onPress={() => setRememberDevice(!rememberDevice)}
              />
            }
            borderWidth={1}
          />
        ) : null}
        {showPrefs ? (
          <Field
            title="Allow Push Notifications"
            value={
              <Switch
                value={allowNotifications}
                onPress={async () => {
                  if (!allowNotifications) {
                    const enabled = await askPushNotificationPermission();
                    if (!enabled) {
                      alert(
                        "Allow Notifications",
                        `You need to first enable push notification in ${
                          Platform.OS === "ios" ? "iOS" : "Android"
                        } settings for iOWNA App`
                      );
                      return;
                    }
                    registerForPushNotifications(pushTokens);
                    setAllowNotifications(!allowNotifications);
                  } else {
                    unRegisterForPushNotifications(pushTokens);
                    setAllowNotifications(!allowNotifications);
                  }
                }}
              />
            }
            borderWidth={0}
          />
        ) : null}
      </View>
    </MainScreen>
  );
});

const RightButton = containerWithoutLoader(({ navigation }) => {
  const web = useAtom(isWebAtom);
  const currentUser = useAtom(currentUserAtom);
  return (
    <HeaderButton
      icon={web ? "create" : ""}
      title={currentUser.firstName ? "Edit" : "Complete"}
      testID="Edit"
      accessibilityLabel="Edit"
      onPress={() => {
        navigation.navigate("UpdateProfileScreen", {
          edit: !!currentUser.firstName,
        });
      }}
    />
  );
});

const ProfileScreenTitle = containerWithoutLoader(({ route }) => {
  const currentUser = useAtom(currentUserAtom);

  const currentUsertitle = currentUser.firstName
    ? nameTitle(get(currentUser, "title", "")) +
      " " +
      get(currentUser, "firstName", "") +
      " " +
      get(currentUser, "lastName", "") +
      "'s Profile"
    : "Your Profile";

  const currentOrganisation = useAtom(currentOrganisationAtom);

  return <BreadCrumb parts={["Activity", currentUsertitle]} />;
});

export const ProfileOptions = (web) => options(ProfileScreenTitle, RightButton);

export const ProfileOptionsBack = (web) => optionsBack(web, ProfileScreenTitle);

const styles = StyleSheet.create({
  nameContainer: {
    backgroundColor: "white",
    flexDirection: "row",
    justifyContent: "space-between",
    paddingHorizontal: 21,
    alignItems: "center",
    paddingVertical: 10,
    borderBottomColor: "#AAAAAA",
    borderBottomWidth: 1,
    width: "100%",
  },
});

export default ProfileScreen;

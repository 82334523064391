import React, { useEffect } from "react";
import { Platform } from "react-native";

import { createStackNavigator } from "@react-navigation/stack";
import { options, optionsBack } from "../../../headerUtils";
import ContactsScreen from "./ContactsScreen";
import BundleScreen from "../BundleNavigator/BundleScreen";
import BundleAdviceListScreen from "../BundleNavigator/BundleAdviceListScreen";
import ContentScreen from "../ContentNavigator/ContentScreen";
import PdfScreen, { PdfOptions } from "../ContentNavigator/PdfScreen";
import FormScreen, { FormOptions } from "../ContentNavigator/FormScreen";
import { ContentSentScreen } from "./ContentSentScreen";
import ManageContactsScreen from "../ContactsNavigator/ContactsScreen";
import ResultsScreen, {
  ResultOptionsBack,
} from "../ActivityNavigator/ResultsScreen";

import SelectListScreenMobile from "./SelectListScreenMobile";
import ActivityScreen from "../ActivityNavigator/ActivityScreen";
import SendPlanScreen from "./SendPlanScreen";
import { useAtom, isWebAtom, setShowContactsModal } from "../../../atoms";
import { HeaderButton, HeaderRight } from "../../../components";
import ProfileScreen from "../ProfileNavigator/ProfileScreen";

import { CommonActions } from "@react-navigation/native";

const Stack = createStackNavigator();
const SendNavigator = ({ navigation, route }) => {
  const web = useAtom(isWebAtom);
  useEffect(() => {
    // This resets params on plan navigator state in the drawer.
    // This means we can unmountOnBlur in the drawer and have a fresh send plan
    // screen every time we navigate back to it from the drawer
    const unsubscribe = navigation.addListener("state", ({ data }) => {
      const state = data.state;
      const route = state.routes.find(
        (route) => route.name === "SendPlanNavigator"
      );

      // console.log(`yolo checking if ${route?.name} needs a reset`);

      if (!!route && !!route.params) {
        navigation.dispatch((state) => {
          return CommonActions.reset({
            ...state,
            routes: state.routes.map(({ params, ...r }) =>
              r.name === route.name ? r : { params, ...r }
            ),
          });
        });
      }
    });
    return unsubscribe;
  }, [navigation]);

  return (
    <Stack.Navigator>
      <Stack.Screen
        name="SendScreen"
        component={SendPlanScreen}
        options={options("Share")}
        initialParams={{
          // contacts: contacttoSend ? [contacttoSend] : [],
          selectedDistributionLists: [],
          selectedContacts: [],
          contents: [],
        }}
      />
      <Stack.Screen
        name="ContentSentScreen"
        component={ContentSentScreen}
        options={optionsBack(web, null)}
      />
      <Stack.Screen
        name="ManageContactsScreen"
        component={ManageContactsScreen}
        options={optionsBack(web, "Manage Contacts", ({ navigation }) => (
          <HeaderButton
            accessibilityLabel={Platform.select({
              android: "Create Contact",
            })}
            icon="add-circle-outline"
            title="Create Contact"
            onPress={() => navigation.setParams({ createContact: true })}
          />
        ))}
        initialParams={route.params === {}}
      />

      <Stack.Screen
        name="SelectBundleScreen"
        component={BundleScreen}
        options={optionsBack(web, "Select Bundle")}
      />
      <Stack.Screen
        name="BundleAdviceListScreen"
        component={BundleAdviceListScreen}
        options={optionsBack(web, ({ route: { params } }) => params.bundleName)}
      />
      <Stack.Screen
        name="SelectListScreen"
        component={SelectListScreenMobile}
        options={optionsBack(web, "Select List")}
      />
      <Stack.Screen
        name="SelectContactScreen"
        component={ContactsScreen}
        options={optionsBack(web, "Select Contact", (props) => (
          <HeaderRight
            title="Manage"
            onPress={() => {
              props.navigation.navigate("ManageContactsScreen");
            }}
          />
        ))}
      />
      <Stack.Screen
        name="ActivityScreen"
        component={ActivityScreen}
        options={optionsBack(web, "Activity")}
      />
      <Stack.Screen
        name="ProfileScreen"
        component={ProfileScreen}
        options={optionsBack(web, "Activity")}
      />
      <Stack.Screen
        name="ContentScreen"
        component={ContentScreen}
        options={optionsBack(web, "Select Content")}
      />

      <Stack.Screen
        name="PdfScreen"
        component={PdfScreen}
        options={PdfOptions(web)}
      />
      <Stack.Screen
        name="FormScreen"
        component={FormScreen}
        options={FormOptions(web)}
      />
      <Stack.Screen
        name="ResultsScreen"
        component={ResultsScreen}
        options={ResultOptionsBack(web)}
      />
    </Stack.Navigator>
  );
};

export default SendNavigator;
